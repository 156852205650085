import type { ReactNode } from 'react';
import { Counterparty } from '@repo/domain-kit/shared';
import { BankAccountAvatar } from '../bank-account-avatar/bank-account-avatar';
import type { TransactionDetails } from '../transactions-table';
import styles from './counterparty.strict-module.css';

export function CashflowTransactionCounterparty({
  transaction,
}: {
  transaction: TransactionDetails;
}): ReactNode {
  const subtitle = (
    <div className={styles['counterparty-subtitle']}>
      {transaction.account ? (
        <BankAccountAvatar
          bankAccount={transaction.account}
          className={styles['counterparty-avatar']}
          size="small"
        />
      ) : null}
      <p>{transaction.displayType}</p>
    </div>
  );

  return (
    <div data-test-counterparty={transaction.id}>
      <Counterparty subtitle={subtitle} title={transaction.counterpartyName} />
    </div>
  );
}
