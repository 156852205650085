import dayjs from 'dayjs';
import { assert } from '@ember/debug';
import type { IntlShape } from 'react-intl';
import type { StatusType } from '@repo/design-system-kit';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { STATUS } from 'qonto/constants/transfers';
import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import {
  IconStatusProcessing,
  IconStatusApproved,
  IconStatusCancelled,
  IconStatusScheduled,
} from 'qonto/react/assets/icons/status';
import type TransferModel from 'qonto/models/transfer';

const statusIcons = [
  'status_approved',
  'status_cancelled',
  'status_processing',
  'status_scheduled',
] as const;

export type StatusIcon = (typeof statusIcons)[number];

export function getStatusIcon(iconName: StatusIcon): React.ReactElement {
  switch (iconName) {
    case 'status_processing':
      return <IconStatusProcessing data-test-status-avatar-icon={iconName} />;
    case 'status_approved':
      return <IconStatusApproved data-test-status-avatar-icon={iconName} />;
    case 'status_scheduled':
      return <IconStatusScheduled data-test-status-avatar-icon={iconName} />;
    case 'status_cancelled':
    default:
      return <IconStatusCancelled data-test-status-avatar-icon={iconName} />;
  }
}

function getLastDate(transfer: TransferModel): string | Date | undefined {
  if (transfer.instant) {
    return transfer.createdAt;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Avoid false positives
  if (transfer.isStanding && transfer.lastRecursionDate) {
    return transfer.lastRecursionDate;
  } else if (transfer.scheduledDate) {
    return transfer.scheduledDate;
  }
}

export function getNextDate(transfer: TransferModel): Date | undefined {
  const nextDate = transfer.isStanding
    ? transfer.nextRecursionDate
    : dayjs(transfer.scheduledDate).toDate();

  if (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Avoid false positives
    nextDate &&
    !transfer.instant &&
    !transfer.isDeclined &&
    !transfer.isCanceled &&
    !transfer.isCompleted
  ) {
    return nextDate;
  } else if (transfer.instant && transfer.isProcessing) {
    return transfer.createdAt;
  }
}

export function getStatusLevel(transfer: TransferModel): StatusType {
  switch (transfer.status) {
    case STATUS.COMPLETED:
      return 'validated';
    case STATUS.CANCELED:
    case STATUS.DECLINED:
      return 'error';
    case STATUS.PENDING:
    case STATUS.STANDING_PROCESSING:
      return 'waiting';
    case STATUS.ACCOUNT_CANCELED:
    case STATUS.BENEFICIARY_ACCOUNT_CANCELED:
    case STATUS.KYB_ONHOLD:
    case STATUS.PENDING_REVIEW:
    case STATUS.PENDING_SEIZURE:
    case STATUS.PROCESSING:
    case STATUS.VALIDATED:
      return 'in-progress';
    default:
      return 'in-progress';
  }
}

export function getStatusText(
  transfer: TransferModel,
  formatMessage: IntlShape['formatMessage']
): string {
  switch (transfer.status) {
    case STATUS.ACCOUNT_CANCELED:
      return formatMessage({ id: 'transfers.status.account-canceled' });
    case STATUS.BENEFICIARY_ACCOUNT_CANCELED:
      return formatMessage({ id: 'transfers.status.beneficiary-account-canceled' });
    case STATUS.CANCELED:
      return formatMessage({ id: 'transfers.status.canceled' });
    case STATUS.COMPLETED:
      return formatMessage({ id: 'transfers.status.completed' });
    case STATUS.DECLINED:
      return formatMessage({ id: 'transfers.status.declined' });
    case STATUS.KYB_ONHOLD:
      return formatMessage({ id: 'transfers.status.kyb-onhold' });
    case STATUS.PENDING:
      return formatMessage({ id: 'transfers.status.pending' });
    case STATUS.PENDING_REVIEW:
      return formatMessage({ id: 'transfers.status.pending-review' });
    case STATUS.PENDING_SEIZURE:
      return formatMessage({ id: 'transfers.status.pending-seizure' });
    case STATUS.PROCESSING:
      return formatMessage({ id: 'transfers.status.processing' });
    case STATUS.VALIDATED:
      return formatMessage({ id: 'transfers.status.validated' });
    case STATUS.STANDING_PROCESSING:
      return formatMessage({ id: 'transfers.status.standing-processing' });
    default:
      assert('Invalid transfer status', transfer.status);
      return '';
  }
}

export function getTransferListType(
  transfer: TransferModel,
  formatMessage: IntlShape['formatMessage']
): string {
  switch (transfer.operationType) {
    case SCHEDULE_OPERATION_TYPES.YEARLY:
      return formatMessage({ id: 'transfers.frequency.standing_yearly' });
    case SCHEDULE_OPERATION_TYPES.HALF_YEARLY:
      return formatMessage({ id: 'transfers.frequency.standing_half_yearly' });
    case SCHEDULE_OPERATION_TYPES.QUARTERLY:
      return formatMessage({ id: 'transfers.frequency.standing_quarterly' });
    case SCHEDULE_OPERATION_TYPES.MONTHLY:
      return formatMessage({ id: 'transfers.frequency.standing_monthly' });
    case SCHEDULE_OPERATION_TYPES.WEEKLY:
      return formatMessage({ id: 'transfers.frequency.standing_weekly' });
    default:
      return '-';
  }
}

export function getEndDateText(
  transfer: TransferModel,
  formatMessage: IntlShape['formatMessage'],
  locale: string
): string {
  const lastDate = getLastDate(transfer);
  const isTransferCancelledOrDeclined = transfer.instant
    ? false
    : transfer.isDeclined || transfer.isCanceled;

  if (isTransferCancelledOrDeclined) {
    return formatMessage({ id: 'transfers.status.canceled' });
  }

  if (lastDate) {
    return dateToken({
      date: dayjs(lastDate).toISOString(),
      locale,
      token: 'date-year-s',
    });
  }

  return '-';
}
