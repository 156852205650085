import type { ReactNode } from 'react';
import { FormattedDate } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type IntlServiceBase from 'ember-intl/services/intl';
import cx from 'clsx';
import { Wallet } from 'qonto/react/assets/icons';
import type RibaPaymentModel from 'qonto/models/riba-payment';
import styles from './styles.strict-module.css';

interface QontoIntlService extends IntlServiceBase {
  formatMoney: (value: number, options: { currency: string }) => string;
}

interface HeaderProps {
  payment: RibaPaymentModel;
}

export function Header({ payment }: HeaderProps): ReactNode {
  const intl = useEmberService('intl') as QontoIntlService;

  return (
    <div className={styles.header}>
      <div className={cx(styles.avatar, 'title-4')}>
        <Wallet />
      </div>
      <div>
        <div className={styles.creditor} data-test-creditor-name>
          {payment.creditorName}
        </div>
        <div className={cx(styles.amount, 'title-2')} data-test-amount>
          -{' '}
          {intl.formatMoney(payment.amount.value, {
            currency: payment.amount.currency,
          })}
        </div>
        <div className={styles.date} data-test-execution-date>
          <FormattedDate day="numeric" month="short" value={payment.executionDate} year="numeric" />
        </div>
      </div>
    </div>
  );
}
