import { useCallback, type ReactNode } from 'react';
import cx from 'clsx';
import { Button } from '@repo/design-system-kit';
import { DialogTrigger } from 'react-aria-components';
import type { SortDirection } from '@tanstack/react-table';
import {
  IconChevronBottomOutlined,
  IconArrowTopOutlined,
  IconArrowBottomOutlined,
} from '@repo/monochrome-icons';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { SORT_DIRECTION, SORT_QUERY_PARAM } from 'qonto/react/constants';
import type { DisplayColumn } from 'qonto/constants/table-view';
import { HeaderCellPopover } from '../popover';
import styles from './styles.strict-module.css';

interface PopoverTriggerProps {
  sortBy?: string;
  handleSortBy?: (sortBy: string | undefined) => void;
  columnId: string;
  updateColumn: (column: Partial<DisplayColumn>) => void;
  showHideColumn?: boolean;
  showSorting?: boolean;
}

export function PopoverTrigger({
  handleSortBy,
  sortBy,
  columnId,
  updateColumn,
  showHideColumn,
  showSorting,
}: PopoverTriggerProps): ReactNode {
  const segment = useEmberService('segment');
  const [sortColumn, sortDirection] = sortBy?.split(':') ?? [];
  const columnSortParam = SORT_QUERY_PARAM[columnId as keyof typeof SORT_QUERY_PARAM];
  const isCurrentColumnSorted = Boolean(
    sortColumn && columnSortParam && sortColumn === columnSortParam
  );

  const onOpenChange = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track('tables_column-header_clicked');
    }
  };

  const getIcon = useCallback((): ReactNode => {
    if (isCurrentColumnSorted) {
      if (sortDirection === SORT_DIRECTION.ASCENDING)
        return (
          <IconArrowTopOutlined className={styles['arrow-icon']} data-testid="icon-arrow-top" />
        );
      else if (sortDirection === SORT_DIRECTION.DESCENDING)
        return (
          <IconArrowBottomOutlined
            className={styles['arrow-icon']}
            data-testid="icon-arrow-bottom"
          />
        );
    }
    return (
      <IconChevronBottomOutlined className={styles['chevron-icon']} data-testid="icon-chevron" />
    );
  }, [isCurrentColumnSorted, sortDirection]);

  return (
    <DialogTrigger onOpenChange={onOpenChange}>
      <Button
        className={cx(
          styles['header-button'],
          isCurrentColumnSorted && styles['header-button-active']
        )}
        data-testid="header-popover-trigger"
        iconOnly
        variant="tertiary"
      >
        {getIcon()}
      </Button>
      <HeaderCellPopover
        columnId={columnId}
        data-testid="header-cell-popover"
        isCurrentColumnSorted={isCurrentColumnSorted}
        onSortBy={handleSortBy}
        showHideColumn={showHideColumn}
        showSorting={showSorting}
        sortedColumn={columnSortParam}
        sortedDirection={sortDirection as SortDirection}
        updateColumn={updateColumn}
      />
    </DialogTrigger>
  );
}
