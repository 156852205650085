import { Button, Flag, Tooltip, type CountryCode } from '@repo/design-system-kit';
import cx from 'clsx';
import {
  Children,
  cloneElement,
  type ComponentPropsWithoutRef,
  isValidElement,
  type ReactNode,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Separator } from 'react-aria-components';
import { CURRENCIES } from 'qonto/constants/international-out/currency';
import { Info } from 'qonto/react/assets/icons/info';
import type { Beneficiary, Fees, Quote } from 'qonto/services/international-out/types';
import { getAllowedDecimalPlaces } from 'qonto/utils/currency';
import {
  formatAccountInformation,
  formatExchangeRateWithDetails,
} from 'qonto/utils/international-out/format';
import { isSwiftNetwork, isSwiftSha } from 'qonto/utils/international-out/quote';
import type { BankAccountModel as BankAccount } from 'qonto/react/models/bank-account';
import styles from './styles.strict-module.css';

function Amount({
  value,
  currency,
  minimumFractionDigits,
  maximumFractionDigits,
}: {
  value: number;
  currency: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}): ReactNode {
  const { formatNumber } = useIntl();

  const formattedNumber = formatNumber(value, {
    style: 'decimal',
    minimumFractionDigits: minimumFractionDigits ?? getAllowedDecimalPlaces(currency),
    maximumFractionDigits: maximumFractionDigits ?? getAllowedDecimalPlaces(currency),
  });

  // To respect the design specifications, we specifically want to display the currency code after the amount, regardless of the locale.
  // That's why we can't use directly the `FormattedNumber` component (from `react-intl`) with the `currencyDisplay` prop set to `code`.
  return (
    <>
      {formattedNumber}&nbsp;{currency}
    </>
  );
}

interface HighlightableProps {
  highlighted?: boolean;
}

function Term({
  children,
  className,
  highlighted = false,
  hint,
  ...props
}: ComponentPropsWithoutRef<'dt'> & HighlightableProps & { hint?: string }): ReactNode {
  return (
    <dt
      className={cx(styles.term, highlighted ? 'title-4' : 'body-2', className)}
      data-test-label
      {...props}
    >
      {children}
      {hint ? (
        <Tooltip label={hint} placement="top" tooltipTestId="tooltip">
          <Button aria-label="tooltip" className={cx(styles.tooltip, 'ml-8')} data-test-tooltip>
            <Info className={styles.icon} />
          </Button>
        </Tooltip>
      ) : null}
    </dt>
  );
}

function Description({
  children,
  className,
  highlighted = false,
  ...props
}: ComponentPropsWithoutRef<'dd'> & HighlightableProps): ReactNode {
  return (
    <dd
      className={cx(styles.description, highlighted ? 'title-4' : 'body-1', className)}
      data-test-value
      {...props}
    >
      {children}
    </dd>
  );
}

function Item({
  children,
  className,
  highlighted = false,
  ...props
}: ComponentPropsWithoutRef<'div'> & HighlightableProps): ReactNode {
  return (
    <div className={cx(styles.item, className)} data-test-item {...props}>
      {Children.map(children, child =>
        isValidElement<HighlightableProps>(child) ? cloneElement(child, { highlighted }) : child
      )}
    </div>
  );
}

function List({ children, className, ...props }: ComponentPropsWithoutRef<'dl'>): ReactNode {
  return (
    <dl className={cx(styles.list, className)} data-test-list {...props}>
      {children}
    </dl>
  );
}

interface SummaryProps extends ComponentPropsWithoutRef<'dl'> {
  account: Pick<BankAccount, 'authorizedBalance' | 'balanceCurrency' | 'name'>;
  beneficiary: Omit<Beneficiary, 'id' | 'paymentType' | 'branchIdentifier'>;
  budgetName?: string | null;
  fees: Fees;
  quote: Pick<
    Quote,
    | 'formattedEstimatedDelivery'
    | 'payOut'
    | 'rate'
    | 'sourceAmount'
    | 'sourceCurrency'
    | 'targetAmount'
    | 'targetCurrency'
  >;
}

export function Summary({
  account,
  beneficiary,
  budgetName,
  fees,
  quote,
  ...props
}: SummaryProps): ReactNode {
  const { formatMessage } = useIntl();

  const exchangeRate = formatExchangeRateWithDetails(quote.rate);
  const isUnity = quote.sourceCurrency === quote.targetCurrency && quote.rate === 1;
  const isSwift = isSwiftNetwork(quote);
  const isSha = isSwiftSha(quote);
  const isShaEuro = isSha && quote.targetCurrency === CURRENCIES.EUR;

  const getFeeBreakdown = (): string => {
    const getSwiftMessage = (): string => {
      if (isShaEuro) {
        return formatMessage({
          id: 'international-out.summary.tooltips.fees.sha-eur',
        });
      }

      if (fees.minimum > 0) {
        return formatMessage(
          {
            id: 'international-out.summary.tooltips.fees.local',
          },
          {
            pricingFee: fees.variable,
            minimumFee: fees.minimum,
          }
        );
      }

      return formatMessage(
        {
          id: 'international-out.summary.tooltips.fees.generic',
        },
        {
          pricingFee: fees.variable,
        }
      );
    };

    const getLocalMessage = (): string => {
      return formatMessage(
        {
          id: 'international-out.summary.tooltips.fees.local',
        },
        {
          pricingFee: fees.variable,
          minimumFee: fees.minimum,
        }
      );
    };

    return isSwift ? getSwiftMessage() : getLocalMessage();
  };

  const computeFeeAmount = (): number => {
    const { fix, total } = fees;

    if (isSwift && !isShaEuro && fix > 0) {
      return total - fix;
    }

    return total;
  };

  return (
    <List data-test-summary {...props}>
      <Item data-test-account>
        <Term>
          <FormattedMessage id="international-out.summary.account-to-debit" />
        </Term>

        <Description>
          <p data-test-account-name>{account.name}</p>

          {account.authorizedBalance !== undefined && account.balanceCurrency ? (
            <p className={cx('body-2', styles['color-secondary'])} data-test-account-balance>
              <Amount currency={account.balanceCurrency} value={account.authorizedBalance} />
            </p>
          ) : null}
        </Description>
      </Item>

      <Item data-test-beneficiary>
        <Term>
          <FormattedMessage id="international-out.summary.beneficiary" />
        </Term>

        <Description>
          <p className={styles['beneficiary-information']}>
            <span className={styles['beneficiary-source']}>
              <Flag
                code={beneficiary.country as CountryCode}
                data-test-beneficiary-country={beneficiary.country}
                size="small"
              />

              <span
                className={cx(
                  styles['beneficiary-currency'],
                  styles['color-secondary'],
                  'caption',
                  'ml-8'
                )}
                data-test-beneficiary-currency
              >
                {beneficiary.currency}
              </span>
            </span>

            <span className={cx('ml-8', styles['break-word'])} data-test-beneficiary-name>
              {beneficiary.name}
            </span>
          </p>

          <p
            className={cx('body-2', styles['break-word'])}
            data-test-beneficiary-account-identifier
          >
            {formatAccountInformation(beneficiary)}
          </p>
        </Description>
      </Item>

      {budgetName ? (
        <Item data-test-budget>
          <Term
            hint={formatMessage({
              id: 'international-out.summary.tooltips.budget',
            })}
          >
            <FormattedMessage id="international-out.summary.budget" />
          </Term>

          <Description data-test-budget-name>{budgetName}</Description>
        </Item>
      ) : null}

      <Separator className={styles.separator} />

      <Item data-test-amount>
        <Term>
          <FormattedMessage id="international-out.summary.beneficiary-receives" />
        </Term>

        <Description>
          <p data-test-amount-target>
            <Amount currency={quote.targetCurrency} value={quote.targetAmount} />
          </p>

          {!isUnity && (
            <p className={cx('body-2', styles['color-secondary'])} data-test-amount-source>
              <Amount currency={quote.sourceCurrency} value={quote.sourceAmount} />
            </p>
          )}
        </Description>
      </Item>

      {!isUnity && (
        <Item data-test-exchange-rate>
          <Term>
            <FormattedMessage id="international-out.summary.exchange-rate" />
          </Term>

          <Description>
            <Amount
              currency={quote.sourceCurrency}
              maximumFractionDigits={0}
              minimumFractionDigits={0}
              value={1}
            />
            &nbsp;=&nbsp;
            <Amount
              currency={quote.targetCurrency}
              maximumFractionDigits={20}
              minimumFractionDigits={exchangeRate.decimalPlacesCount}
              value={Number(exchangeRate.value)}
            />
          </Description>
        </Item>
      )}

      {Boolean(isSwift) && !isShaEuro && fees.fix > 0 && (
        <Item data-test-swift-fee>
          <Term
            hint={formatMessage({
              id: `international-out.summary.tooltips.fees.${isSha ? 'sha' : 'our'}`,
            })}
          >
            <FormattedMessage id="international-out.summary.swift-fee" />
          </Term>

          <Description>
            <Amount currency={quote.sourceCurrency} value={fees.fix} />
          </Description>
        </Item>
      )}

      <Item data-test-fee>
        <Term hint={getFeeBreakdown()}>
          <FormattedMessage id="international-out.summary.transfer-fee" />
        </Term>

        <Description>
          <Amount currency={quote.sourceCurrency} value={computeFeeAmount()} />
        </Description>
      </Item>

      <Separator className={styles.separator} />

      <Item data-test-arrival-time>
        <Term>
          <FormattedMessage id="international-out.summary.eta" />
        </Term>

        <Description>
          {quote.formattedEstimatedDelivery.replace(/^./, match => match.toUpperCase())}
        </Description>
      </Item>

      <Item data-test-total-amount highlighted>
        <Term>
          <FormattedMessage id="international-out.summary.total-amount" />
        </Term>

        <Description>
          <Amount currency={quote.sourceCurrency} value={quote.sourceAmount + fees.total} />
        </Description>
      </Item>
    </List>
  );
}
