import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import styles from './styles.strict-module.css';

interface FlowLinkManager {
  transitionTo: (options: {
    name: string;
    stepId: string;
    queryParams: { origin: string };
  }) => Promise<void>;
}

interface NotSignedStateProps {
  amount: {
    value: number;
    currency: string;
  };
}

export function NotSignedState({ amount }: NotSignedStateProps): JSX.Element {
  const flowLinkManager = useEmberService('flow-link-manager') as unknown as FlowLinkManager;
  const segment = useEmberService('segment');
  const zendeskLocalization = useEmberService('zendesk-localization');
  const { formatMessage } = useIntl();

  const handleFaqClick = (): void => {
    segment.track('pay-later-faq_clicked', {
      origin: 'cockpit_not_signed',
    });
  };

  const handleFlowEntrance = (): void => {
    segment.track('pay-later-cockpit_activation_clicked');

    void flowLinkManager.transitionTo({
      name: 'pay-later-application',
      stepId: 'intro',
      queryParams: { origin: 'cockpit' },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h2 className="title-2 mb-16" data-test-upsell-title>
          <FormattedMessage
            id="financing.pay-later.cockpit.not-signed.title"
            values={{
              amount: (
                <FormattedNumber
                  currency={amount.currency}
                  minimumFractionDigits={0}
                  style="currency"
                  value={amount.value}
                />
              ),
            }}
          />
        </h2>

        <p className="body-2 mb-32" data-test-upsell-description>
          <FormattedMessage id="financing.pay-later.cockpit.not-signed.description" />
        </p>

        <div className={styles.actions}>
          <Button className="mr-16" data-test-not-signed-primary-cta onPress={handleFlowEntrance}>
            <FormattedMessage id="financing.pay-later.cockpit.not-signed.primary-cta" />
          </Button>

          <a
            className="btn btn--tertiary"
            data-test-not-signed-secondary-cta
            href={formatMessage(
              { id: 'financing.pay-later.faq-url' },
              { faqUrl: zendeskLocalization.getLocalizedArticle(8271688) }
            )}
            onClick={handleFaqClick}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage id="financing.pay-later.cockpit.not-signed.secondary-cta" />
          </a>
        </div>
      </div>

      <LottiePlayer
        autoplay
        className={styles.illustration}
        data-test-not-signed-illustration=""
        loop={false}
        path="/lotties/financing/pay-later/eligibility.json"
      />
    </div>
  );
}
