import { useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  type ColumnDef,
  createColumnHelper,
  flexRender,
  type ExpandedState,
  type Header,
  type CellContext,
} from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import dayjs from 'dayjs';
import { ArrowRight } from 'qonto/react/assets/icons/arrow-right';
import { ArrowDown } from 'qonto/react/assets/icons/arrow-down';
import { IconCog } from 'qonto/react/assets/icons/cash-flow-categories/icon-cog';
import { setTransactionsFilterByCategories } from 'qonto/react/components/cash-flow/utils/transactions-filter.ts';
import {
  cashFlowSidePanelManager,
  type CashFlowSidePanelPayload,
} from 'qonto/react/contexts/cash-flow-sidepanel-context';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { useOrganizationNavigation } from 'qonto/react/shared/hooks/use-organization-navigation';
import {
  generateLoadingCategoriesData,
  generateLoadingHeaders,
} from '../../utils/generate-loading-data';
import { TableCell } from '../shared/table-cell';
import type {
  CategoriesTableColumn,
  CategoriesTableRow,
} from '../../models/categories-table-display';
import { MemoizedTableCell } from '../shared/table-cell/table-cell';
import { CategoryCell } from './category-cell';
import { BalanceCell } from './balance-cell';
import styles from './styles.strict-module.css';

interface TableCellContext extends CellContext<CategoriesTableRow, CategoriesTableColumn[]> {
  rowIndex: number;
  columnIndex: number;
}

export interface Amount {
  value: string;
  currency: string;
}

interface TableMeta {
  selectedCategoriesIds: string | undefined;
  selectedIntervalStart: number | undefined;
  selectedFlowType: string | undefined;
}

// In order to include catgeories row & flow type header row
type CustomRow = Omit<CategoriesTableRow, 'type'> & {
  type: CategoriesTableRow['type'] | 'flowType';
};

const isRowSelected = (
  row: Pick<CustomRow, 'id' | 'type' | 'flowType'>,
  selectedCategoriesIds: string | undefined,
  selectedFlowType: string | undefined,
  isFlowSelected = false
): boolean => {
  if (selectedCategoriesIds === row.id) {
    return true;
  }

  if (row.type === 'flowType' && isFlowSelected && selectedFlowType === row.flowType) {
    return true;
  }

  if (
    row.type === 'uncategorized' &&
    selectedCategoriesIds === '' &&
    selectedFlowType === row.flowType
  ) {
    return true;
  }

  return false;
};

const normalizeToStartOfDay = (timestamp: number | undefined): string | undefined => {
  if (!timestamp) return undefined;
  return dayjs(timestamp).format('YYYY-MM-DD');
};

const isCellSelected = (
  row: Pick<CustomRow, 'id' | 'type' | 'flowType'>,
  meta: TableMeta,
  intervalStart: number | undefined,
  isFlowSelected = false
): boolean => {
  const isSelectedCategory = isRowSelected(
    row,
    meta.selectedCategoriesIds,
    meta.selectedFlowType,
    isFlowSelected
  );
  const normalizedSelectedInterval = normalizeToStartOfDay(meta.selectedIntervalStart);
  const normalizedInterval = normalizeToStartOfDay(intervalStart);
  const isSelectedInterval = normalizedSelectedInterval === normalizedInterval;
  return isSelectedCategory && isSelectedInterval;
};

const buildTable = (
  data: CategoriesTableRow[],
  sums: CategoriesTableColumn[],
  isLoading: boolean,
  numberOfColumns: number,
  headerLabel: string,
  onCellNavigation: (params: {
    rowIndex: number;
    columnIndex: number;
    direction: 'up' | 'down' | 'left' | 'right';
    enterEditMode: boolean;
  }) => void,
  selectedCategoriesIds: string | undefined,
  selectedIntervalStart: number | undefined,
  selectedFlowType: string | undefined,
  isFlowSelected: boolean,
  isForecastEditingEnabled?: boolean,
  onForecastEntryUpdate?: () => void,
  onViewTransactions?: (payload: CashFlowSidePanelPayload) => void
): {
  tableData: CategoriesTableRow[];
  columns: ColumnDef<CategoriesTableRow>[];
  meta: TableMeta;
} => {
  const headersData = isLoading
    ? generateLoadingHeaders(numberOfColumns)
    : Array.from({ length: numberOfColumns }, (_, index) => String(index));

  const tableData = isLoading ? generateLoadingCategoriesData(numberOfColumns) : data;
  const columnHelper = createColumnHelper<CategoriesTableRow>();
  const columns = [
    columnHelper.accessor('name', {
      header: headerLabel,
      cell: info => (
        <CategoryCell
          canExpand={info.row.getCanExpand()}
          color={info.row.original.color}
          icon={info.row.original.icon}
          isExpanded={info.row.getIsExpanded()}
          name={info.getValue()}
          onExpandToggle={info.row.getToggleExpandedHandler()}
          type={info.row.original.type}
        />
      ),
    }),
    ...headersData.map((col, index) =>
      columnHelper.accessor('columns', {
        id: col,
        header: info => {
          const meta = info.table.options.meta as TableMeta;
          const flowType = data[0]?.flowType ?? 'inflows';

          // Allows to keep the flow type header highlighted across intervals
          const isSelectedCell = isCellSelected(
            { id: flowType, type: 'flowType', flowType },
            meta,
            sums[index]?.interval?.start,
            isFlowSelected
          );

          return (
            <BalanceCell
              amount={sums[index]?.amount}
              categories={data}
              forecastAmount={sums[index]?.forecastAmountSum}
              interval={sums[index]?.interval}
              isFlowSelected
              isSelectedCell={isSelectedCell}
              key={`header-${sums[index]?.interval?.start}-${flowType}-${isSelectedCell}`}
              onViewTransactions={onViewTransactions}
            />
          );
        },
        cell: info => {
          const { rowIndex, columnIndex } = info as TableCellContext;
          const meta = info.table.options.meta as TableMeta;

          const isUncategorized = info.row.original.type === 'uncategorized';
          const isSubcategory = info.row.original.type === 'subcategory';
          const enableForecastEditing =
            !isUncategorized && isForecastEditingEnabled && isSubcategory;

          const isSelectedCell = isCellSelected(
            info.row.original,
            meta,
            sums[index]?.interval?.start
          );

          return (
            <BalanceCell
              amount={info.getValue()[index]?.amount}
              categories={[info.row.original]}
              forecastAmount={info.getValue()[index]?.forecastAmount}
              interval={sums[index]?.interval}
              isForecastEditingEnabled={enableForecastEditing}
              isSelectedCell={isSelectedCell}
              key={`${info.row.original.id}-${sums[index]?.interval?.start}-${info.row.original.flowType}-${isSelectedCell}`}
              onForecastEntryUpdate={onForecastEntryUpdate}
              onNavigateDown={(enterEditMode: boolean): void => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'down', enterEditMode });
              }}
              onNavigateLeft={(enterEditMode: boolean) => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'left', enterEditMode });
              }}
              onNavigateRight={(enterEditMode: boolean): void => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'right', enterEditMode });
              }}
              onNavigateUp={(enterEditMode: boolean): void => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'up', enterEditMode });
              }}
              onViewTransactions={onViewTransactions}
            />
          );
        },
      })
    ),
  ] as ColumnDef<CategoriesTableRow>[];

  return {
    tableData,
    columns,
    meta: {
      selectedCategoriesIds,
      selectedIntervalStart,
      selectedFlowType,
    },
  };
};

interface CategoriesTableProps {
  data: CategoriesTableRow[];
  sums: CategoriesTableColumn[];
  isLoading?: boolean;
  numberOfColumns?: number;
  headerLabelKey?: string;
  currentDateIndex?: number;
  bankAccounts?: string | undefined;
  isForecastEditingEnabled?: boolean;
  tbodyRef?: React.RefObject<HTMLTableSectionElement>;
  onCellNavigation: (params: {
    rowIndex: number;
    columnIndex: number;
    direction: 'up' | 'down' | 'left' | 'right';
    enterEditMode: boolean;
  }) => void;
  onForecastEntryUpdate?: () => void;
}

export function CategoriesTable({
  data,
  sums,
  isLoading = false,
  numberOfColumns = 4,
  headerLabelKey,
  currentDateIndex,
  bankAccounts,
  isForecastEditingEnabled = false,
  tbodyRef,
  onCellNavigation,
  onForecastEntryUpdate,
  ...props
}: CategoriesTableProps): ReactNode {
  const { formatMessage } = useIntl();
  const abilities = useEmberService('abilities');
  const segment = useEmberService('segment');
  const organizationNavigation = useOrganizationNavigation();
  const { organization } = useOrganizationManager();
  const headerLabel = useMemo(
    () => formatMessage({ id: headerLabelKey }),
    [formatMessage, headerLabelKey]
  );
  const {
    openSidepanelWith,
    selectedInterval: contextSelectedInterval,
    selectedCategories: contextSelectedCategories,
    isFlowSelected: contextIsFlowSelected,
  } = cashFlowSidePanelManager.useCashFlowSidePanel();
  const selectedCategoriesIds = contextSelectedCategories.map(category => category.id).join(',');
  const selectedFlowType = contextSelectedCategories[0]?.flowType;
  const selectedIntervalStart = contextSelectedInterval?.start;

  const expandedTableLocalStorageKey = useMemo(
    () => `cashflow-categories-${headerLabel.toLowerCase()}-table`,
    [headerLabel]
  );

  const expandedRowsLocalStorageKey = useMemo(
    () => `cashflow-categories-${headerLabel.toLowerCase()}-table-rows`,
    [headerLabel]
  );

  const cachedExpandedTableState = localStorage.getItem(expandedTableLocalStorageKey);
  const cachedExpandedRowsState = localStorage.getItem(expandedRowsLocalStorageKey);

  const [isTableExpanded, setIsTableExpanded] = useState(
    cachedExpandedTableState ? (JSON.parse(cachedExpandedTableState) as boolean) : true
  );
  const [expandedRows, setExpandedRows] = useState<ExpandedState>(
    cachedExpandedRowsState ? (JSON.parse(cachedExpandedRowsState) as ExpandedState) : true
  );

  useEffect(() => {
    localStorage.setItem(expandedTableLocalStorageKey, JSON.stringify(isTableExpanded));
  }, [isTableExpanded, expandedTableLocalStorageKey]);

  useEffect(() => {
    localStorage.setItem(expandedRowsLocalStorageKey, JSON.stringify(expandedRows));
  }, [expandedRows, expandedRowsLocalStorageKey]);

  useEffect(() => {
    const expandedRowsCount = Object.keys(expandedRows).length;
    if (isTableExpanded && expandedRowsCount) {
      segment.track('cash-flow_category_expand');
    }
  }, [expandedRows, segment, isTableExpanded]);

  const handleViewTransactions = useCallback(
    ({ selectedCategories, selectedInterval, isFlowSelected }: CashFlowSidePanelPayload): void => {
      const isUnlabeled =
        selectedCategories.length === 1 && selectedCategories[0]?.type === 'uncategorized';

      segment.track('cash-flow_cell_view-transactions', {
        type: selectedCategories[0]?.type,
        isUnlabeled,
      });

      if (abilities.can('view sidepanel transactions cash-flow')) {
        openSidepanelWith({ selectedCategories, selectedInterval, isFlowSelected });
      } else {
        setTransactionsFilterByCategories(selectedCategories, selectedInterval, organization.id);

        let url = `/transactions`;
        if (bankAccounts) {
          url += `?bank-accounts=${bankAccounts}`;
        }
        void organizationNavigation(url);
      }
    },
    [abilities, bankAccounts, organizationNavigation, openSidepanelWith, organization.id, segment]
  );

  const { tableData, columns, meta } = useMemo(
    () =>
      buildTable(
        data,
        sums,
        isLoading,
        numberOfColumns,
        headerLabel,
        onCellNavigation,
        selectedCategoriesIds,
        selectedIntervalStart,
        selectedFlowType,
        contextIsFlowSelected,
        isForecastEditingEnabled,
        onForecastEntryUpdate,
        handleViewTransactions
      ),
    [
      data,
      sums,
      isLoading,
      numberOfColumns,
      headerLabel,
      onCellNavigation,
      isForecastEditingEnabled,
      onForecastEntryUpdate,
      handleViewTransactions,
      selectedCategoriesIds,
      selectedIntervalStart,
      selectedFlowType,
      contextIsFlowSelected,
    ]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    meta,
    state: {
      expanded: expandedRows,
    },
    onExpandedChange: setExpandedRows,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: row => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: row => Boolean(row.subRows.length),
  });

  const handleManageCategories = (header: Header<CategoriesTableRow, unknown>): void => {
    const title =
      typeof header.column.columnDef.header === 'string'
        ? header.column.columnDef.header.toLowerCase()
        : undefined;
    if (title) {
      segment.track('cash-flow_category_manage');
      void organizationNavigation(`/cash-flow-categories/${title}`);
    }
  };

  return (
    <table aria-busy={isLoading} aria-live="polite" className={styles.categoriesTable} {...props}>
      <colgroup>
        <col />
        {Array.from({ length: numberOfColumns }, (_, index) => (
          <col key={index} />
        ))}
      </colgroup>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => {
              const isFirstColumn = index === 0;
              return (
                <th
                  className={cx(
                    styles.colHeader,
                    !isTableExpanded && styles.tableCollapsed,
                    currentDateIndex === index && styles.current
                  )}
                  key={header.id}
                  scope="col"
                >
                  {header.isPlaceholder ? null : (
                    <TableCell
                      align={isFirstColumn ? 'left' : 'center'}
                      data-header-col={index}
                      isLoading={isFirstColumn ? false : isLoading}
                    >
                      {isFirstColumn ? (
                        <div className={styles.colHeaderActions}>
                          <div className={styles.colHeaderActionsLabel}>
                            <button
                              className={styles.categoriesActionButton}
                              data-testid="expand-table-button"
                              onClick={() => {
                                setIsTableExpanded(!isTableExpanded);
                              }}
                              type="button"
                            >
                              {isTableExpanded ? <ArrowDown /> : <ArrowRight />}
                            </button>
                            <span data-testid="table-title">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </span>
                          </div>

                          <button
                            className={styles.categoriesActionButton}
                            data-testid="manage-categories-button"
                            onClick={() => {
                              handleManageCategories(header);
                            }}
                            type="button"
                          >
                            <IconCog data-testid="manage-categories-icon" />
                          </button>
                        </div>
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </TableCell>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>

      <tbody ref={tbodyRef}>
        {isTableExpanded
          ? table.getRowModel().rows.map((row, rowIndex) => {
              const dataIsSubcategory =
                row.original.type === 'subcategory' || row.original.type === 'uncategorized';

              const isSelected = isRowSelected(
                row.original,
                meta.selectedCategoriesIds,
                meta.selectedFlowType
              );

              const rowKey = `${row.id}-${
                isSelected ? 'selected' : 'unselected'
              }-${row.original.type}`;

              return (
                <tr data-is-editable={dataIsSubcategory} key={rowKey}>
                  {row.getVisibleCells().map((cell, cellIndex) => {
                    const isCurrentDate = cellIndex === currentDateIndex;

                    if (cellIndex === 0) {
                      return (
                        <th
                          className={styles.rowHeader}
                          data-testid="row-header"
                          key={cell.id}
                          scope="row"
                        >
                          <TableCell
                            align="left"
                            data-col={cellIndex}
                            data-row={rowIndex}
                            isLabel
                            isLoading={isLoading}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        </th>
                      );
                    }

                    const intervalStart = sums[cellIndex - 1]?.interval?.start;
                    const isSelectedCell = isCellSelected(row.original, meta, intervalStart);
                    const keyId = `${cell.id}-${intervalStart}-${row.original.flowType}-${isSelectedCell}`;

                    if (dataIsSubcategory) {
                      const cellValues = cell.getValue() as CategoriesTableColumn[];
                      const amount = cellValues[cellIndex - 1]?.amount?.value;

                      return (
                        <td
                          className={`${styles.rowCell} ${isCurrentDate ? styles.current : ''}`}
                          key={keyId}
                          role="gridcell"
                        >
                          <MemoizedTableCell
                            amount={amount}
                            data-col={cellIndex}
                            data-row={rowIndex}
                            isLoading={isLoading}
                          >
                            {flexRender(cell.column.columnDef.cell, {
                              ...cell.getContext(),
                              rowIndex,
                              columnIndex: cellIndex,
                            })}
                          </MemoizedTableCell>
                        </td>
                      );
                    }

                    return (
                      <td
                        className={`${styles.rowCell} ${isCurrentDate ? styles.current : ''}`}
                        key={keyId}
                        role="gridcell"
                      >
                        <TableCell data-col={cellIndex} data-row={rowIndex} isLoading={isLoading}>
                          {flexRender(cell.column.columnDef.cell, {
                            ...cell.getContext(),
                            rowIndex,
                            columnIndex: cellIndex,
                          })}
                        </TableCell>
                      </td>
                    );
                  })}
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );
}
