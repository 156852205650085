import { IconCheckmarkOutlined } from '@repo/monochrome-icons';
import { type PropsWithChildren, type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface OptionProps {
  className?: string;
  content?: string;
}

export function Option({
  children,
  className,
  content,
  ...props
}: PropsWithChildren<OptionProps>): ReactNode {
  // span should be replaced with li once we can get rid of ReactBridge
  // where the component is used
  return (
    <span className={cx(styles.option, className)} {...props}>
      <IconCheckmarkOutlined aria-hidden="true" className={styles.checkmark} />
      {children ? children : content}
    </span>
  );
}
