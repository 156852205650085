import { forwardRef } from 'react';
import type { ReactElement, HTMLProps, ReactNode, Ref } from 'react';
import { Button, TextAreaField, TextField } from '@repo/design-system-kit';
import { IconPlusOutlined } from '@repo/monochrome-icons';
import { DragHandle, useSortable } from '@repo/domain-kit/shared';
import { useIntl } from 'react-intl';
import styles from './styles.strict-module.css';

type UniqueIdentifier = string | number;

export interface ItemInterface {
  id: UniqueIdentifier;
  title?: string;
}

export interface SectionInterface {
  description?: string;
  id: UniqueIdentifier;
  title?: string;
  items: ItemInterface[];
}

interface SectionProps<T extends SectionInterface> extends HTMLProps<HTMLDivElement> {
  section: T;
  asDefaultSection?: boolean;
  disabled?: boolean;
  onAddItem: (sectionId: T['id']) => void;
  onTitleChange: (title: string) => void;
  onDescriptionChange: (description: string) => void;
  onRemove: (id: T['id']) => void;
}

function SectionWithoutRef<T extends SectionInterface>(
  {
    section,
    asDefaultSection,
    disabled,
    onAddItem,
    onDescriptionChange,
    onTitleChange,
    onRemove,
    ...props
  }: SectionProps<T>,
  ref: Ref<HTMLDivElement>
): ReactNode {
  const { formatMessage } = useIntl();
  const { isSorting } = useSortable({
    id: section.id,
  });

  function handleAddItem(): void {
    onAddItem(section.id);
  }

  function handleRemoveSection(): void {
    onRemove(section.id);
  }

  const items = section.items.map((item, index) => (
    <div className={styles.item} data-test-item={item.id} key={item.id}>
      {index + 1}. {item.title}
    </div>
  ));

  if (asDefaultSection) {
    return (
      <div
        className={styles['default-section']}
        data-test-section={section.id}
        {...props}
        ref={ref}
      >
        {items}
      </div>
    );
  }

  return (
    <div className={styles.section} data-test-section={section.id} {...props} ref={ref}>
      <DragHandle className={styles.handle} id={section.id} />
      <TextField
        data-test-section-title-input=""
        isDisabled={disabled}
        isRequired
        label={formatMessage({
          id: 'receivable-invoices.invoice-creation.sections.title.label',
        })}
        name="section_title"
        onChange={onTitleChange}
        placeholder={formatMessage({
          id: 'receivable-invoices.invoice-creation.sections.title.placeholder',
        })}
        value={section.title}
      />
      <TextAreaField
        data-test-section-description-input=""
        isDisabled={disabled}
        isOptional
        label={formatMessage({
          id: 'receivable-invoices.invoice-creation.sections.description.label',
        })}
        name="section_description"
        onChange={onDescriptionChange}
        placeholder={formatMessage({
          id: 'receivable-invoices.invoice-creation.sections.description.placeholder',
        })}
        value={section.description}
      />
      {!isSorting ? items : null}
      {!disabled ? (
        <div className={styles.actions}>
          <Button data-test-add-item-cta="" onPress={handleAddItem} variant="tertiary">
            <IconPlusOutlined className={styles.icon} height={16} width={16} />{' '}
            {formatMessage({ id: 'receivable-invoices.invoice-creation.add-item.cta' })}
          </Button>
          <Button data-test-remove-section-cta="" onPress={handleRemoveSection} variant="tertiary">
            {formatMessage({ id: 'receivable-invoices.invoice-creation.remove-section.cta' })}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export const Section = forwardRef(SectionWithoutRef) as <T extends SectionInterface>(
  p: SectionProps<T> & { ref?: Ref<HTMLDivElement> }
) => ReactElement;
