import { useEffect, type ReactNode } from 'react';
import { BalanceCell, BaseTable, Counterparty, ExpectedDate } from '@repo/domain-kit/shared';
import { type ColumnDef } from '@tanstack/react-table';
import { LoadingTransactionRow, TypeCell } from '@repo/domain-kit/cashflow';
import { IconUnionOutlined } from '@repo/monochrome-icons';
import {
  useUpcomingTransactions,
  type UpcomingTransaction,
} from 'qonto/react/hooks/use-upcoming-transactions';
import styles from './styles.strict-module.css';

const columns: ColumnDef<UpcomingTransaction>[] = [
  {
    accessorKey: 'counterparty_name',
    header: 'Counterparty',
    enableSorting: true,
    cell: info => (
      <Counterparty
        icon={<IconUnionOutlined />}
        subtitle={info.row.original.metadata?.invoice_number}
        title={info.row.original.counterparty_name}
      />
    ),
  },
  {
    accessorKey: 'provider_object_type',
    header: 'Type',
    enableSorting: true,
    cell: info => (
      <TypeCell
        type={
          info.row.original.provider_object_type as
            | 'Subscription'
            | 'Client invoice'
            | 'Check'
            | 'Supplier invoice'
        }
      />
    ),
  },
  {
    accessorKey: 'due_date',
    header: 'Expected Date',
    enableSorting: true,
    cell: info => <ExpectedDate date={info.row.original.due_date} />,
  },
  {
    accessorKey: 'amount.value',
    header: 'Amount',
    enableSorting: true,
    cell: info => (
      <BalanceCell
        amount={{
          value: parseFloat(info.row.original.amount.value),
          currency: info.row.original.amount.currency,
        }}
        displayCurrencySymbol
        side={info.row.original.side}
      />
    ),
  },
];

interface UpcomingTransactionsTableProps {
  onEmptyStateChange: (isEmpty: boolean) => void;
}

export function UpcomingTransactionsTable({
  onEmptyStateChange,
}: UpcomingTransactionsTableProps): ReactNode {
  const MAX_TRANSACTIONS = 1000;
  const { data, isPending } = useUpcomingTransactions(MAX_TRANSACTIONS, 1, 'pending');
  useEffect(() => {
    if (!isPending) {
      const isEmpty = !data?.upcoming_transactions?.length;
      onEmptyStateChange(isEmpty);
    }
  }, [data, isPending, onEmptyStateChange]);

  return (
    <div className={styles['table-container']}>
      <BaseTable
        columns={columns}
        data={data?.upcoming_transactions || []}
        isLoading={isPending}
        loadingComponent={LoadingTransactionRow()}
        pageSize={25}
        pageSizes={[25, 50, 100]}
        total={data?.meta?.total_count || 0}
      />
    </div>
  );
}
