import cx from 'clsx';
import type { Key, ReactNode } from 'react';
import { Button, ListBox, ListBoxItem, Popover, Select } from 'react-aria-components';
import { capitalize } from '@ember/string';
import { useIntl } from 'react-intl';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import type { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import styles from './styles.strict-module.css';

interface FrequencySelectorProps {
  onFrequencyChange: (frequency: CashflowPeriodRate) => void;
  onOpenChange?: (isOpen: boolean) => void;
  selectedFrequency: CashflowPeriodRate;
}

export function FrequencySelector({
  onFrequencyChange,
  onOpenChange,
  selectedFrequency,
}: FrequencySelectorProps): ReactNode {
  const { formatMessage } = useIntl();

  const frequencyDisplayMap: Partial<Record<CashflowPeriodRate, string>> = {
    monthly: formatMessage({ id: 'cash-flow.frequency-selector.month' }),
    quarterly: formatMessage({ id: 'cash-flow.frequency-selector.quarter' }),
    yearly: formatMessage({ id: 'cash-flow.frequency-selector.year' }),
  };

  const availableFrequencies = Object.entries(frequencyDisplayMap).map(([frequency, label]) => ({
    key: frequency,
    label: capitalize(label),
  }));

  const handleFrequencyChange = (key: Key): void => {
    onFrequencyChange(key as CashflowPeriodRate);
  };

  return (
    <Select
      className={styles.select}
      data-testid="frequency-selector"
      onOpenChange={onOpenChange}
      onSelectionChange={handleFrequencyChange}
      selectedKey={selectedFrequency}
    >
      <Button className={cx('title-4', styles['popover-trigger'])} data-testid="trigger">
        {frequencyDisplayMap[selectedFrequency]}
        <IconChevronBottomOutlined aria-hidden="true" className={styles['arrow-icon']} />
      </Button>
      <Popover>
        <ListBox
          className={styles['list-box']}
          data-testid="frequency-options"
          items={availableFrequencies}
        >
          {item => (
            <ListBoxItem
              className={cx('body-2', styles['list-item'])}
              data-testid={item.key}
              key={item.key}
            >
              {item.label}
            </ListBoxItem>
          )}
        </ListBox>
      </Popover>
    </Select>
  );
}
