import { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
// @ts-expect-error -- TODO remove this when ui-kit constants are typed
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Disclaimer, PromotionalCard, type BadgeProps } from '@repo/design-system-kit';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { useDownloadWithHeaders } from 'qonto/react/hooks/use-download-with-headers';
import { EmailPreviewFooter } from 'qonto/react/components/receivable-invoices/email-preview/footer';
import styles from './email-preview.strict-module.css';

interface BadgeType {
  TRY_FOR_FREE: BadgeProps['type'];
}

interface EmailPreviewProps extends ComponentPropsWithoutRef<'div'> {
  displayLanguage: string;
  emailMessageLines?: string[];
  emailSubject: string;
  featureFlagEinvoicingAcquisition?: boolean;
  featureFlagEinvoicingFrRollback?: boolean;
  invoicingDocument: {
    amountDue: string;
    directDebitEnabled?: boolean;
    currency?: string;
    customerSnapshot?: {
      name: string;
      billingAddress?: {
        countryCode: string;
      };
      countryCode?: string;
    };
    dueDate: string;
    expiryDate: string;
    isEinvoice?: boolean;
    number: string;
    organization?: { name: string };
    quote?: { get: (key: 'number') => string };
    paymentLink?: { methods?: string[] };
    reference?: string;
  };
  isCurrentThemeDark?: boolean;
  isDraft?: boolean;
  isQuote?: boolean;
  isSubscription?: boolean;
  logo?: {
    fileUrl?: string;
  };
  shareableLink?: string;
}

export function EmailPreview({
  className,
  displayLanguage,
  emailMessageLines,
  emailSubject,
  featureFlagEinvoicingFrRollback,
  featureFlagEinvoicingAcquisition,
  invoicingDocument,
  isCurrentThemeDark,
  isDraft,
  isQuote,
  isSubscription,
  logo,
  shareableLink,
  ...rest
}: EmailPreviewProps): ReactNode {
  const intl = useIntl();
  const { formatMessage } = useIntl();

  const { downloadFile } = useDownloadWithHeaders({ href: shareableLink || '' });

  const badgeTypes = BADGE_TYPE as BadgeType;

  const abilities = useEmberService('abilities');

  const organizationManager = useEmberService('organizationManager');

  const canWritePaymentLink = abilities.can('write paymentLink');

  const relatedQuoteNumber = invoicingDocument.quote?.get('number') || '';

  const currency = invoicingDocument.currency || 'EUR';

  const showPaymentReferenceDisclaimer =
    !isDraft &&
    !isQuote &&
    !invoicingDocument.directDebitEnabled &&
    !invoicingDocument.isEinvoice &&
    !(abilities.can('write paymentLink') && invoicingDocument.paymentLink?.methods?.length);

  const footerDisclaimer = formatMessage(
    {
      id: 'receivable-invoices.invoicing-sending.preview.invoicing-disclaimer',
    },
    {
      language: displayLanguage,
    },
    { ignoreTag: true }
  );

  const isFrenchCustomer =
    invoicingDocument.customerSnapshot?.billingAddress?.countryCode === 'FR' ||
    invoicingDocument.customerSnapshot?.countryCode === 'FR';

  const isFrenchOrga = organizationManager.organization.isFrench;

  const displayEinvoicingAcquisitionPromoCard =
    !invoicingDocument.isEinvoice &&
    isFrenchCustomer &&
    isFrenchOrga &&
    featureFlagEinvoicingAcquisition;

  function disabledAction(): void {
    // Do nothing
  }

  async function handleQuoteDownload(): Promise<void> {
    await downloadFile();
  }

  return (
    <>
      <div className={cx(styles['email-container'], className)} data-test-preview-email {...rest}>
        <div className={displayEinvoicingAcquisitionPromoCard ? 'mb-32' : undefined}>
          {logo ? (
            <div className="mb-32">
              <img alt="" data-test-preview-email-logo src={logo.fileUrl} />
            </div>
          ) : null}

          <div
            className={cx(styles['email-subject'], 'title-2 mb-32')}
            data-test-preview-email-subject
          >
            {emailSubject}
          </div>

          <div className={cx(styles['email-body'], 'body-1')}>
            {emailMessageLines?.map((line, index) => (
              <>
                <span data-test-preview-email-message-line={index} key={line}>
                  {line}
                </span>
                <br />
              </>
            ))}

            {isDraft ? (
              <>
                <span data-test-preview-email-sent-by-lang>
                  <FormattedMessage
                    id="receivable-invoices.share-email.preview.data.sent-by"
                    values={{ language: displayLanguage }}
                  />
                </span>
                <span> </span>
                <span className={styles['invoice-info']} data-test-preview-email-sent-by-data>
                  {invoicingDocument.organization?.name}
                </span>
              </>
            ) : (
              <>
                <span data-test-preview-email-invoice-number>
                  {isQuote ? (
                    <FormattedMessage
                      id="receivable-invoices.quote-sending.preview.data.quote.title"
                      values={{ language: displayLanguage }}
                    />
                  ) : (
                    <FormattedMessage
                      id="receivable-invoices.share-email.preview.data.invoice-number"
                      values={{ language: displayLanguage }}
                    />
                  )}
                </span>
                <span> </span>
                <span
                  className={styles['invoice-info']}
                  data-test-preview-email-invoice-number-data
                >
                  {invoicingDocument.number}
                </span>
              </>
            )}
            <br />

            <span data-test-preview-email-total-amount-lang>
              <FormattedMessage
                id="receivable-invoices.share-email.preview.data.total-amount"
                values={{ language: displayLanguage }}
              />
            </span>
            <span> </span>
            <span className={styles['invoice-info']} data-test-preview-email-total-amount-data>
              {intl.formatNumber(Number(invoicingDocument.amountDue), {
                currency,
                style: 'currency',
                currencyDisplay: 'code',
              })}
            </span>
            <br />

            <span data-test-preview-email-due-date-lang>
              {isQuote ? (
                <FormattedMessage
                  id="receivable-invoices.quote-sending.preview.data.quote.expiry-date"
                  values={{ language: displayLanguage }}
                />
              ) : (
                <FormattedMessage
                  id="receivable-invoices.share-email.preview.data.due-date"
                  values={{ language: displayLanguage }}
                />
              )}
            </span>
            <span> </span>
            <span className={styles['invoice-info']} data-test-preview-email-due-date-data>
              {isQuote
                ? dateToken({
                    date: invoicingDocument.expiryDate,
                    locale: displayLanguage,
                    token: 'compact',
                  })
                : dateToken({
                    date: invoicingDocument.dueDate,
                    locale: displayLanguage,
                    token: 'compact',
                  })}
            </span>
            <br />

            {isDraft ? (
              <>
                <span data-test-preview-email-sent-to-lang>
                  <FormattedMessage
                    id="receivable-invoices.share-email.preview.data.sent-to"
                    values={{ language: displayLanguage }}
                  />
                </span>
                <span> </span>
                <span className={styles['invoice-info']} data-test-preview-email-sent-to-data>
                  {invoicingDocument.customerSnapshot?.name}
                </span>
                <br />
              </>
            ) : null}

            {relatedQuoteNumber ? (
              <>
                <span data-test-preview-email-related-quote-lang>
                  <FormattedMessage
                    id="receivable-invoices.share-email.preview.data.related-quote-number"
                    values={{ language: displayLanguage }}
                  />
                </span>
                <span> </span>
                <span className={styles['invoice-info']} data-test-preview-email-related-quote-data>
                  {relatedQuoteNumber}
                </span>
                <br />
              </>
            ) : null}

            {showPaymentReferenceDisclaimer ? (
              <Disclaimer.Inline
                className={styles['reference-disclaimer']}
                data-test-preview-email-reference-disclaimer=""
                level="info"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      { id: 'receivable-invoices.share-email.preview.data.disclaimer' },
                      {
                        language: displayLanguage,
                        invoice_number: invoicingDocument.number,
                        payment_reference: invoicingDocument.reference,
                      },
                      { ignoreTag: true }
                    ),
                  }}
                />
              </Disclaimer.Inline>
            ) : null}
          </div>

          {isQuote ? (
            <a
              className="btn btn--primary btn--medium btn--stretch"
              data-test-preview-email-view-invoice-button
              href={shareableLink}
              onClick={event => {
                event.preventDefault();
                (() => {
                  void handleQuoteDownload();
                })();
              }}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage
                id="receivable-invoices.quote-sending.preview.cta"
                values={{ language: displayLanguage }}
              />
            </a>
          ) : null}

          {isSubscription ? (
            <span
              className={cx(
                styles['fake-button'],
                invoicingDocument.isEinvoice && featureFlagEinvoicingFrRollback
                  ? 'mb-24'
                  : undefined
              )}
              data-test-preview-email-view-invoice-button
            >
              <FormattedMessage
                id="receivable-invoices.share-email.preview.CTA"
                values={{ language: displayLanguage }}
              />
            </span>
          ) : null}

          {!isQuote && !isSubscription ? (
            <a
              className={cx(
                invoicingDocument.isEinvoice && featureFlagEinvoicingFrRollback
                  ? 'btn btn--primary btn--medium btn--stretch mb-24'
                  : 'btn btn--primary btn--medium btn--stretch'
              )}
              data-test-preview-email-view-invoice-button
              href={shareableLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              {isDraft ? (
                <FormattedMessage
                  id="receivable-invoices.share-email.draft.preview.CTA"
                  values={{ language: displayLanguage }}
                />
              ) : null}

              {canWritePaymentLink && !isDraft ? (
                <FormattedMessage
                  id="receivable-invoices.share-email.preview.pay-cta"
                  values={{ language: displayLanguage }}
                />
              ) : null}

              {!canWritePaymentLink && !isDraft ? (
                <FormattedMessage
                  id="receivable-invoices.share-email.preview.CTA"
                  values={{ language: displayLanguage }}
                />
              ) : null}
            </a>
          ) : null}

          {invoicingDocument.isEinvoice && featureFlagEinvoicingFrRollback ? (
            <span
              className={cx(styles['einvoice-body'], 'body-1')}
              data-test-preview-email-fr-einvoice-info
            >
              <FormattedMessage
                id="receivable-invoices.invoicing-sending.preview.einvocing-text.body"
                values={{ language: displayLanguage }}
              />
            </span>
          ) : null}
        </div>

        {displayEinvoicingAcquisitionPromoCard ? (
          <PromotionalCard
            badgeText={formatMessage(
              {
                id: 'einvoicing.promotions.promotional-card.acquisition.badge',
              },
              {
                language: displayLanguage,
              }
            )}
            badgeType={badgeTypes.TRY_FOR_FREE}
            className={styles['disabled-promo-card']}
            ctaText={formatMessage(
              {
                id: 'einvoicing.promotions.promotional-card.acquisition.cta',
              },
              {
                language: displayLanguage,
              }
            )}
            data-test-einvoicing-acquisition-promo-card=""
            onCtaClick={disabledAction}
            subtitle={formatMessage(
              {
                id: 'einvoicing.promotions.promotional-card.acquisition.subtitle',
              },
              {
                language: displayLanguage,
              }
            )}
          />
        ) : null}
      </div>

      <EmailPreviewFooter
        disclaimer={footerDisclaimer}
        isCurrentThemeDark={isCurrentThemeDark}
        language={displayLanguage}
      />
    </>
  );
}
