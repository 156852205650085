import type { ReactElement } from 'react';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { Status } from 'qonto/react/graphql';
import { useTransactionOperationMethodLabel } from 'qonto/react/hooks/use-transaction-operation-method';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import styles from './styles.strict-module.css';

export function PaymentMethodCell(): ReactElement {
  const transaction = cellContextManager.useCellContext();
  const { operationMethod, status } = transaction;
  const isDeclined = status === Status.Declined || status === Status.Reversed;
  const operationMethodLabel = useTransactionOperationMethodLabel(operationMethod);

  return (
    <BaseCell className={isDeclined ? styles.declined : undefined} data-testid="payment-method">
      {operationMethodLabel}
    </BaseCell>
  );
}
