import type { ReactNode } from 'react';
import dayjs from 'dayjs';
import { Button } from '@repo/design-system-kit';
import {
  IconChevronLeftOutlined,
  IconChevronRightOutlined,
  IconCrossOutlined,
} from '@repo/monochrome-icons';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks/use-ember-service';
import type { LabelTableInterval } from '../../../models/labels-cashflow-display';
import type { CategoriesTableRow } from '../../../models/categories-table-display';
import styles from './header.strict-module.css';

interface SidePanelHeaderProps {
  selectedInterval: LabelTableInterval | undefined;
  frequencyFormat: string;
  selectedCategories: CategoriesTableRow[];
  onCloseSidepanel: () => void;
  isFlowSelected: boolean;
  periodNavigation: {
    onNextMonth?: () => void;
    isLastPeriod?: boolean;
    onPreviousMonth?: () => void;
    isFirstPeriod?: boolean;
  };
}

export function SidepanelHeader({
  selectedInterval,
  frequencyFormat,
  selectedCategories,
  onCloseSidepanel,
  isFlowSelected,
  periodNavigation,
}: SidePanelHeaderProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const queryClient = useQueryClient();

  const getFlowLabel = (): string => {
    if (selectedCategories[0]?.flowType === 'inflows') {
      return formatMessage({ id: 'cash-flow-categories.group-title.inflows' });
    }
    return formatMessage({ id: 'cash-flow-categories.group-title.outflows' });
  };

  // First step towards optimizing sidepanl rendering on quick navigation
  const clearTransactionsCache = (): void => {
    queryClient.removeQueries({ queryKey: ['infinite-transactions'] });
  };

  const trackPeriodNavigation = (direction: 'previous' | 'next'): void => {
    segment.track('cash-flow_sidepanel_navigate-period', {
      direction,
    });
  };

  const trackCloseSidepanel = (): void => {
    segment.track('cash-flow_sidepanel_close');
  };

  const categoryName = isFlowSelected ? getFlowLabel() : (selectedCategories[0]?.name ?? '');
  return (
    <header className={styles.container} data-testid="cash-flow-sidepanel-header">
      <div>
        <div className={styles.period}>
          <div>
            {/*TODO: add aria-label*/}
            <Button
              data-testid="cash-flow-sidepanel-header-previous-period"
              iconOnly
              isDisabled={periodNavigation.isFirstPeriod}
              onPress={() => {
                clearTransactionsCache();
                trackPeriodNavigation('previous');
                periodNavigation.onPreviousMonth?.();
              }}
              size="small"
              variant="tertiary"
            >
              <IconChevronLeftOutlined />
            </Button>
            <Button
              data-testid="cash-flow-sidepanel-header-next-period"
              iconOnly
              isDisabled={periodNavigation.isLastPeriod}
              onPress={() => {
                clearTransactionsCache();
                trackPeriodNavigation('next');
                periodNavigation.onNextMonth?.();
              }}
              size="small"
              variant="tertiary"
            >
              <IconChevronRightOutlined />
            </Button>
          </div>
          <span
            className={cx('body-2', styles.periodTitle)}
            data-testid="cash-flow-sidepanel-header-period"
          >
            {dayjs(selectedInterval?.start).format(frequencyFormat)}
          </span>
        </div>
        <h1 className="title-3" data-testid="cash-flow-sidepanel-header-category">
          {categoryName}
        </h1>
      </div>
      <span>
        <Button
          aria-label={formatMessage({ id: 'a11y.buttons.close-aria-label' })}
          data-testid="cash-flow-sidepanel-header-close"
          iconOnly
          onPress={() => {
            trackCloseSidepanel();
            onCloseSidepanel();
          }}
          size="medium"
          variant="tertiary"
        >
          <IconCrossOutlined />
        </Button>
      </span>
    </header>
  );
}
