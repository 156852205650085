import { useCallback, useEffect, useState, type ReactNode } from 'react';
import { useIntl, type IntlShape } from 'react-intl';
import { useEventCallback } from 'usehooks-ts';
import type { UploadResult, Uppy } from '@uppy/core';
import { Spinner } from '@repo/design-system-kit';
import useUppyState from '@uppy/react/lib/useUppyState';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import {
  IconAttachmentOutlined,
  IconAttachmentWarningOutlined,
  IconCheckmarkRoundedOutlined,
} from '@repo/monochrome-icons';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { Status, type Attachment } from 'qonto/react/graphql';
import type { DataWithIconProps } from 'qonto/react/components/table-v2/cells/data-with-icon-cell/data-with-icon-cell';
import { AttachmentMissingOutlined } from 'qonto/react/assets/icons/attachment-missing-outlined';
import {
  useAttachmentsUploader,
  type Body,
  type Meta,
} from 'qonto/react/hooks/use-attachments-uploader';
import { apiBaseURL } from 'qonto/constants/hosts';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { useFetchApi } from 'qonto/react/hooks/use-fetch-api';
import { useRefetchTransaction } from 'qonto/react/hooks/mutations/use-refetch-transaction';
import {
  ATTACHMENT_CELL_CLICK_EVENT_NAME,
  ATTACHMENT_POPOVER_CLICK_EVENT_NAME,
  MAX_ATTACHMENT_COUNT,
  UPLOAD_DISCLAIMER_TIMEOUT,
} from 'qonto/react/constants';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import type Segment from 'qonto/services/segment';
import { AttachmentCellPopover } from './popover/attachment-cell-popover';

interface NoAttachmentProps {
  uppy: Uppy<Meta, Body>;
  isUploading: boolean;
  onOpenChange: (isOpen: boolean) => void;
  attachmentRequestedAt: string;
}

function NoAttachment({
  onOpenChange,
  isUploading,
  uppy,
  attachmentRequestedAt,
}: NoAttachmentProps): ReactNode {
  const { attachmentRequired: required, attachmentLost: lost } =
    cellContextManager.useCellContext();
  const intl = useIntl();
  const { type, title, icon } = getNoAttachmentStatus(lost, required, intl, isUploading);
  const subtitle = attachmentRequestedAt
    ? intl.formatMessage(
        { id: 'transactions.table.attachments.status.subtitle.last-request' },
        {
          date: dateToken({
            date: attachmentRequestedAt,
            locale: intl.locale,
            token: 'date-year-s',
          }),
        }
      )
    : null;

  return (
    <BaseCell
      data-testid="attachment-cell"
      onOpenChange={onOpenChange}
      popoverSlot={
        <AttachmentCellPopover
          icon={icon}
          isUploading={isUploading}
          subtitle={subtitle}
          title={title}
          uppy={uppy}
        />
      }
    >
      <DataWithIconCell icon={icon} isEmptyCell subtitle={subtitle} title={title} type={type} />
    </BaseCell>
  );
}

interface SingleAttachmentProps {
  uppy: Uppy<Meta, Body>;
  isUploading: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

function SingleAttachment({ isUploading, uppy, onOpenChange }: SingleAttachmentProps): ReactNode {
  const {
    attachments,
    attachmentLost: lost,
    attachmentRequired,
  } = cellContextManager.useCellContext();
  const { formatMessage } = useIntl();
  if (!attachments[0]?.file) {
    return null;
  }

  const { probativeAttachment } = attachments[0];
  const title = attachments.length.toString();
  const getCellMeta = (): {
    title: string;
    subtitle?: string;
    type?: 'error' | undefined;
    icon: ReactNode;
  } => {
    if (isUploading) {
      return {
        title: formatMessage({
          id: 'transactions.table.attachments.status.title.uploading',
        }),
        icon: <Spinner color="primary-a" />,
      };
    }

    if (lost) {
      return {
        title,
        subtitle: formatMessage({
          id: 'transactions.table.attachments.status.title.lost',
        }),
        type: 'error',
        icon: <IconAttachmentWarningOutlined />,
      };
    }

    if (!attachmentRequired) {
      return {
        title,
        subtitle: formatMessage({
          id: 'transactions.table.attachments.status.not-required',
        }),
        icon: <IconCheckmarkRoundedOutlined />,
      };
    }

    return {
      title,
      icon: <IconAttachmentOutlined />,
      subtitle:
        probativeAttachment?.status === 'corrupted'
          ? formatMessage({
              id: 'transactions.table.attachments.status.title.not-certified',
            })
          : undefined,
    };
  };

  return (
    <BaseCell
      data-testid="attachment-cell"
      onOpenChange={onOpenChange}
      popoverSlot={
        <AttachmentCellPopover isUploading={isUploading} uppy={uppy} {...getCellMeta()} />
      }
    >
      <DataWithIconCell {...getCellMeta()} />
    </BaseCell>
  );
}

export function AttachmentCell(): ReactNode {
  const {
    id: transactionId,
    attachments,
    attachmentRequestedAt,
    attachmentRequired: required,
    attachmentLost: lost,
    status,
  } = cellContextManager.useCellContext();
  const { mutate: refetchTransaction, isPending } = useRefetchTransaction(transactionId);
  const fetchApi = useFetchApi();
  const { formatMessage } = useIntl();
  const [isLinkingAttachments, setIsLinkingAttachments] = useState(false);
  const segment = useEmberService('segment');
  const isDeclined = status === Status.Declined || status === Status.Reversed;

  const onComplete = useEventCallback((result: UploadResult<Meta, Body>) => {
    const { successful } = result;
    const linkSuccessfulUploads = async (): Promise<void> => {
      const attachmentIds = successful?.map(({ response }) => {
        return response?.body?.attachment.id;
      });

      if (attachmentIds?.length === 0) {
        return;
      }

      await fetchApi(`v7/transactions/${transactionId}/link_attachments`, {
        method: 'PATCH',
        body: JSON.stringify({
          transaction: { attachment_ids: attachmentIds },
        }),
      });
    };

    if (successful?.length) {
      const trackSuccessfulUploads = (): void => {
        segment.track(ATTACHMENT_POPOVER_CLICK_EVENT_NAME, {
          attachments_action_type: 'attachment_uploaded',
          table: 'transactions',
        });
      };

      setIsLinkingAttachments(true);
      linkSuccessfulUploads()
        .then(() => {
          successful.forEach(() => {
            trackSuccessfulUploads();
          });

          setIsLinkingAttachments(false);
          refetchTransaction(undefined, {
            onSuccess: () => {
              uppy.clear();
            },
          });
        })
        .catch(() => {
          uppy.info(
            {
              message: formatMessage({
                id: 'transactions.table.attachments.popover.error.upload-failed',
              }),
            },
            'error',
            UPLOAD_DISCLAIMER_TIMEOUT
          );
        })
        .finally(() => {
          setIsLinkingAttachments(false);
        });
    } else {
      uppy.info(
        {
          message: formatMessage({
            id: 'transactions.table.attachments.popover.error.upload-failed',
          }),
        },
        'error',
        UPLOAD_DISCLAIMER_TIMEOUT
      );
    }
  });

  const uppy = useAttachmentsUploader({
    endpoint: `${apiBaseURL}/v3/attachments`,
    onComplete,
    maxNumberOfFiles: MAX_ATTACHMENT_COUNT - attachments.length,
  });

  useEffect(() => {
    uppy.setOptions({
      restrictions: {
        maxNumberOfFiles: MAX_ATTACHMENT_COUNT - attachments.length,
      },
    });
  }, [attachments.length, uppy]);

  const currentUploads = useUppyState(uppy, state => state.currentUploads);
  const isUploading = Object.values(currentUploads).length > 0 || isLinkingAttachments || isPending;

  const handleOpenChange = useCallback(
    (isOpen: boolean): void => {
      if (!isOpen) {
        if (!isUploading) {
          uppy.setState({ info: [] });
          uppy.clear();
        }
      } else trackCellState(segment, attachments, attachmentRequestedAt, lost, required);
    },
    [isUploading, uppy, attachments, attachmentRequestedAt, lost, required, segment]
  );

  if (isDeclined) {
    return (
      <BaseCell>
        <span data-testid="empty">-</span>
      </BaseCell>
    );
  }

  if (!attachments.length) {
    return (
      <NoAttachment
        attachmentRequestedAt={attachmentRequestedAt}
        isUploading={isUploading}
        onOpenChange={handleOpenChange}
        uppy={uppy}
      />
    );
  }

  if (attachments.length === 1) {
    return (
      <SingleAttachment isUploading={isUploading} onOpenChange={handleOpenChange} uppy={uppy} />
    );
  }

  const getCellMeta = (): {
    title: string;
    subtitle?: string;
    type?: 'error' | undefined;
    icon: ReactNode;
  } => {
    if (isUploading) {
      return {
        title: formatMessage({
          id: 'transactions.table.attachments.status.title.uploading',
        }),
        icon: <Spinner color="primary-a" />,
      };
    }

    const { pending, corrupted } = getMultiProbativeStatus(attachments);
    let subtitle: string | undefined;
    let type: 'error' | undefined;
    let icon = <IconAttachmentOutlined />;
    if (corrupted > 0) {
      subtitle = formatMessage(
        {
          id: 'transactions.table.attachments.status.subtitle.not-certified',
        },
        {
          count: corrupted,
        }
      );
    } else if (pending) {
      //TODO: handle scanning status
    }

    if (!required) {
      subtitle = formatMessage({
        id: 'transactions.table.attachments.status.not-required',
      });
      icon = <IconCheckmarkRoundedOutlined />;
    }

    if (lost) {
      subtitle = formatMessage({
        id: 'transactions.table.attachments.status.title.lost',
      });
      type = 'error';
      icon = <IconAttachmentWarningOutlined />;
    }

    return {
      title: attachments.length.toString(),
      type,
      subtitle,
      icon,
    };
  };

  return (
    <BaseCell
      data-testid="attachment-cell"
      onOpenChange={handleOpenChange}
      popoverSlot={
        <AttachmentCellPopover isUploading={isUploading} uppy={uppy} {...getCellMeta()} />
      }
    >
      <DataWithIconCell {...getCellMeta()} />
    </BaseCell>
  );
}

const getMultiProbativeStatus = (
  attachments: Attachment[]
): { pending: number; corrupted: number } =>
  attachments.reduce(
    (acc, attachment) => {
      const { probativeAttachment } = attachment;
      if (probativeAttachment) {
        const { pending, corrupted } = acc;
        if (probativeAttachment.status === 'pending') {
          return { corrupted, pending: pending + 1 };
        }
        if (probativeAttachment.status === 'corrupted') {
          return { pending, corrupted: corrupted + 1 };
        }
      }
      return acc;
    },
    { pending: 0, corrupted: 0 }
  );

const getNoAttachmentStatus = (
  lost: boolean,
  required: boolean,
  intl: IntlShape,
  isUploading: boolean
): {
  type?: DataWithIconProps['type'];
  title: string;
  icon: ReactNode;
} => {
  if (isUploading) {
    return {
      type: 'info',
      title: intl.formatMessage({
        id: 'transactions.table.attachments.status.title.uploading',
      }),
      icon: <Spinner color="primary-a" />,
    };
  }

  if (lost) {
    return {
      type: 'error',
      title: intl.formatMessage({
        id: 'transactions.table.attachments.status.title.lost',
      }),
      icon: <IconAttachmentWarningOutlined />,
    };
  }

  if (required) {
    return {
      type: 'info',
      title: intl.formatMessage({
        id: 'transactions.table.attachments.status.title.missing',
      }),
      icon: <AttachmentMissingOutlined />,
    };
  }

  return {
    title: intl.formatMessage({
      id: 'transactions.table.attachments.status.not-required',
    }),
    icon: <IconCheckmarkRoundedOutlined />,
  };
};

function trackCellState(
  segment: Segment,
  attachments: Attachment[],
  attachmentRequestedAt: string | null | undefined,
  lost: boolean,
  required: boolean
): void {
  let state: string;
  if (lost) {
    state = 'lost';
  } else if (required) {
    state = 'required';
  } else {
    state = 'not_required';
  }

  let count: string;
  if (attachments.length === 0) {
    count = 'empty';
  } else if (attachments.length === 1) {
    count = 'single';
  } else {
    count = 'multiple';
  }

  segment.track(ATTACHMENT_CELL_CLICK_EVENT_NAME, {
    state: count === 'empty' && Boolean(attachmentRequestedAt) ? 'requested' : state,
    count,
  });
}
