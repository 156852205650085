import { BadgeStatus } from '@repo/design-system-kit';
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import styles from './item.strict-module.css';

type PromotionalVoucherFeeType = 'physical_card_plus' | 'physical_card_metal' | 'additional_user';

interface BaseDiscount {
  finishOn: string;
  recurrenceOn: string;
  voucherAmount: number;
  voucherMonthDuration: number;
}

interface NonPromotionalDiscount extends BaseDiscount {
  voucherType: 'trial' | 'initial' | 'subscription';
  voucherFeeType?: never;
}

interface PromotionalDiscount extends BaseDiscount {
  voucherType: 'promotional';
  voucherFeeType: PromotionalVoucherFeeType;
}

type Discount = NonPromotionalDiscount | PromotionalDiscount;

interface SubscriptionPromoCodeItemProps {
  discount: Discount;
  isActive?: boolean;
}

export function SubscriptionPromoCodeItem({
  discount,
  isActive,
  ...props
}: SubscriptionPromoCodeItemProps): ReactNode {
  const { formatMessage, formatDate, locale } = useIntl();

  const getTitle = (): string => {
    const titles = {
      initial: formatMessage({ id: 'subscription.promo-code.item.voucher-type.initial' }),
      trial: formatMessage({ id: 'subscription.promo-code.item.voucher-type.free-trial' }),
      subscription: formatMessage({ id: 'subscription.promo-code.item.voucher-type.subscription' }),
      promotional: {
        physical_card_plus: formatMessage({
          id: 'subscription.promo-code.item.voucher-type.promotional.plus',
        }),
        physical_card_metal: formatMessage({
          id: 'subscription.promo-code.item.voucher-type.promotional.metal',
        }),
        additional_user: formatMessage({
          id: 'subscription.promo-code.item.voucher-type.promotional.additional-user',
        }),
      },
    };

    if (discount.voucherType === 'promotional') {
      return titles.promotional[discount.voucherFeeType];
    }
    return titles[discount.voucherType];
  };

  return (
    <div className={styles['promotion-box']} {...props}>
      <div className={styles['promotion-detail']}>
        <p className={styles['promotion-name']} data-test-display-promotion-type>
          {getTitle()}
        </p>
        <p className={styles['promotion-date']} data-test-display-promotion-date>
          {isActive
            ? formatMessage(
                { id: 'subscription.promo-code.item.active.description' },
                {
                  amount: formatMoney(discount.voucherAmount, {
                    locale,
                    signus: '-',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                  month: discount.voucherMonthDuration,
                  date: formatDate(discount.finishOn, {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  }),
                }
              )
            : formatMessage(
                { id: 'subscription.promo-code.item.waiting.description' },
                {
                  amount: formatMoney(discount.voucherAmount, {
                    locale,
                    signus: '-',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }),
                  month: discount.voucherMonthDuration,
                  date: formatDate(discount.recurrenceOn, {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  }),
                }
              )}
        </p>
      </div>
      <div className={styles['badge-wrapper']}>
        <BadgeStatus
          data-test-display-promotion-status-badge={isActive ? 'active' : 'waiting'}
          level={isActive ? 'validated' : 'waiting'}
          text={formatMessage({
            id: isActive
              ? 'subscription.promo-code.item.active.label'
              : 'subscription.promo-code.item.waiting.label',
          })}
        />
      </div>
    </div>
  );
}
