import type LabelModel from 'qonto/models/label';
import type TransactionModel from 'qonto/models/transaction';
import type {
  Side,
  Status,
  Transaction,
  OperationMethod,
  ActivityTag,
  OperationType,
  Label,
  Attachment,
} from 'qonto/react/graphql';

export type PartialTransaction = Omit<
  Transaction,
  // Not needed to be translated to GraphQL
  | 'vat'
  | 'organizationId'
  | 'bankAccount'
  | 'bankAccountId'
  | 'initiator'
  // Fields not available in transaction ember model
  | 'attachmentRequestedAt'
  | 'disputes'
  | 'statusHistory'
  | 'settledBalance'
  | 'payableInvoices'
  | 'receivableInvoices'
  | 'providerObjectId'
>;

/**
 * Turns an EmberData transaction into a GraphQL transaction object.
 *
 * @see Not all the GraphQL fields are available on the EmberData model.
 *
 * Fields that are not available on EmberData:
 * - threads
 * - billingTransfer
 *
 * Fields that are not included since they are not expected to change:
 * - initiator
 * - bankAccount
 */
export const serializeTransaction = (
  transactionModel: TransactionModel,
  customAttributes?: Partial<Transaction>
): PartialTransaction => {
  return {
    activityTag: transactionModel.activityTag.toUpperCase() as ActivityTag,
    displayAt: transactionModel.displayAt.toString(),
    operationType: transactionModel.operationType.toUpperCase() as OperationType,
    amount: {
      value: transactionModel.amount.toFixed(2),
      currency: transactionModel.amountCurrency,
    },
    localAmount: {
      value: transactionModel.localAmount.toFixed(2),
      currency: transactionModel.localAmountCurrency,
    },
    note: transactionModel.note,
    side: transactionModel.side.toUpperCase() as Side,
    slug: transactionModel.slug,
    status: transactionModel.status.toUpperCase() as Status,
    qualifiedForAccounting: transactionModel.qualifiedForAccounting,
    attachmentLost: transactionModel.attachmentLost,
    attachmentRequired: transactionModel.attachmentRequired,
    attachmentIds: transactionModel.attachmentIds,
    hasAttachment: transactionModel.attachmentIds.length > 0,
    attachmentSuggestionIds: transactionModel.attachmentSuggestionIds,
    automaticallyAddedAttachmentIds: transactionModel.automaticallyAddedAttachmentIds,
    attachmentRequested: transactionModel.attachmentRequested,
    rawCounterpartyName: transactionModel.rawCounterpartyName,
    counterpartyName: transactionModel.counterpartyName,
    fx: transactionModel.fx,
    operationMethod: transactionModel.operationMethod.toUpperCase() as OperationMethod,
    id: transactionModel.id,
    description: transactionModel.description,
    enrichmentData: serializeEnrichmentData(transactionModel.enrichmentData),
    settledAt: transactionModel.settledAt?.toISOString(),
    emittedAt: transactionModel.emittedAt.toString(),
    subjectType: transactionModel.subjectType,
    attachments: serializeAttachments(transactionModel.attachments),
    labels: serializeLabels(transactionModel.labels),
    labelIds: serializeLabelIds(transactionModel.labels),
    ...customAttributes,
  };
};

const serializeLabelIds = (labels: TransactionModel['labels']): string[] => {
  return labels.map(label => label.id as string);
};

const serializeLabels = (labels: TransactionModel['labels']): Label[] => {
  return labels.map(
    (label: LabelModel): Label => ({
      id: label.id as string,
      name: label.name,
      listId: label.labelList.id as string,
      labelList: {
        id: label.labelList.id as string,
        name: label.labelList.name,
        color: label.labelList.color,
        rank: label.labelList.rank,
      },
    })
  );
};

const serializeAttachments = (attachments: TransactionModel['attachments']): Attachment[] => {
  return attachments.map(attachment => ({
    id: attachment.id,
    createdAt: attachment.createdAt.toISOString(),
    downloadUrl: attachment.downloadUrl,
    file: {
      name: attachment.file.fileName,
      contentType: attachment.file.fileContentType,
      size: attachment.file.fileSize,
      url: attachment.file.fileUrl,
    },
    probativeAttachment: {
      status: attachment.probativeAttachment.status,
      fileName: attachment.probativeAttachment.fileName,
      fileContentType: attachment.probativeAttachment.fileContentType,
      fileSize: attachment.probativeAttachment.fileSize,
      fileUrl: attachment.probativeAttachment.fileUrl,
      downloadUrl: attachment.probativeAttachment.downloadUrl,
    },
    slug: attachment.slug ?? '',
    thumbnail: { fileUrl: attachment.thumbnail.fileUrl },
  }));
};

/**
 * This function is required since enrichmentData is always returned as empty object when "null" by the backend
 * hence ember data is not able to use the defaulValue for it.
 *
 * GraphQL requires a deep object serialization for it
 */
const serializeEnrichmentData = (
  enrichmentData?: TransactionModel['enrichmentData']
): PartialTransaction['enrichmentData'] => {
  if (!enrichmentData?.logo) {
    return {
      logo: { small: '', medium: '' },
    };
  }
  return enrichmentData;
};
