import type { ReactNode } from 'react';
import cx from 'clsx';
import { Status } from 'qonto/react/graphql';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { useFormattedAmount } from 'qonto/react/hooks/ui';
import { BaseCell } from '../base-cell';
import { CopyButton } from '../../buttons';
import styles from './styles.strict-module.css';

export function SettledBalanceCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { settledBalance, status } = transaction;
  const { currency, value } = settledBalance;
  const { formattedAmount } = useFormattedAmount({
    amount: { currency, value: Number(value) },
  });
  const isDeclined = status === Status.Declined || status === Status.Reversed;
  const isPending = status === Status.Pending;

  return (
    <BaseCell
      actionSlot={
        isPending ? null : (
          <CopyButton text={value} trackingEventSource="settled_balance_cell_copy_amount" />
        )
      }
      align="right"
    >
      <span
        className={cx(styles.amount, isDeclined && styles.declined)}
        data-testid="settled-balance-cell"
      >
        {isPending ? '-' : `${formattedAmount} ${currency}`}
      </span>
    </BaseCell>
  );
}
