import type { ReactNode } from 'react';
import {
  Sidebar as BaseSidebar,
  type SidebarProps as BaseSidebarProps,
  type SidebarSlots,
} from 'qonto/react/components/sidebar';
import type RibaPaymentModel from 'qonto/models/riba-payment';
import { Header } from './header';
import { Details } from './details';
import { Timeline } from './timeline';

interface SidebarProps extends BaseSidebarProps {
  payment: RibaPaymentModel;
}

function getSidebarSlots(payment?: RibaPaymentModel): SidebarSlots | undefined {
  if (!payment) {
    return undefined;
  }

  const contents = [
    <Details key="payment-details" payment={payment} />,
    <Timeline key="payment-timeline" payment={payment} />,
  ];

  return {
    header: <Header payment={payment} />,
    contents,
  };
}

export function Sidebar({ isOpened, onClose, payment, title }: SidebarProps): ReactNode {
  return (
    <BaseSidebar
      isOpened={isOpened}
      onClose={onClose}
      slots={getSidebarSlots(payment)}
      title={title}
    />
  );
}
