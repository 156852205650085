import React, { useEffect, useState, type ReactNode } from 'react';
import { Button, Popup } from '@repo/design-system-kit';
import { useEmberService, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import ENV from 'qonto/config/environment';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import type Organization from 'qonto/models/organization';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';

export const AR_PROMO_POPUP_STORAGE_KEY = 'ar-de-promo-popup-dismissed';

const isTest = ENV.environment === 'test';

const popupDelay = isTest ? 0 : 2000;

interface OrganizationManager {
  organization: Organization | null;
}

export function ArPromotionalPopup(): ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const segment = useEmberService('segment');
  const { formatMessage } = useIntl();
  const { organization } = useOrganizationManager() as OrganizationManager;
  const router = useRouter();

  useEffect(() => {
    if (safeLocalStorage.getItem(AR_PROMO_POPUP_STORAGE_KEY) === 'true') {
      return;
    }

    const timeout = setTimeout(() => {
      setIsOpen(true);
      segment.track('discovery_promotional-pop-up_displayed', { feature: 'e-invoicing-de' });
    }, popupDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [segment]);

  const handleCancel = (): void => {
    segment.track('discovery_promotional-pop-up_closed', { feature: 'e-invoicing-de' });

    safeLocalStorage.setItem(AR_PROMO_POPUP_STORAGE_KEY, 'true');

    setIsOpen(false);
  };

  const handleConfirm = (): void => {
    safeLocalStorage.setItem(AR_PROMO_POPUP_STORAGE_KEY, 'true');

    segment.track('discovery_promotional-pop-up_clicked', {
      feature: 'e-invoicing-de',
    });

    void router.push(`/organizations/${organization?.slug}/receivable-invoices/new`);
  };

  // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
  const TEST_PORTAL_CONTAINER =
    document.getElementById('ember-testing') ?? document.querySelector('main') ?? undefined;

  return (
    <Popup.Root
      data-test-id="ar-promotional-popup"
      isOpen={isOpen}
      portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
    >
      <Popup.CloseButton onPress={handleCancel} />
      <Popup.Illustration isPromotional>
        <img
          alt=""
          data-test-id="ar-promotional-popup-illustration"
          src="/illustrations/ar-promotional-popup/illustration-de.png"
          srcSet="/illustrations/ar-promotional-popup/illustration-de@2x.png"
        />
      </Popup.Illustration>
      <Popup.Header>
        <Popup.Title>{formatMessage({ id: 'ar-de.pop-up.title' })}</Popup.Title>
        <Popup.Subtitle>{formatMessage({ id: 'ar-de.pop-up.description' })}</Popup.Subtitle>
      </Popup.Header>
      <Popup.Body />
      <Popup.Footer>
        <Button aria-label="Confirm" onPress={handleConfirm} variant="primary">
          {formatMessage({ id: 'ar-de.pop-up.cta' })}
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
