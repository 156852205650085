import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@repo/design-system-kit';
import { FlowHeader, type FlowHeaderProps } from '@repo/domain-kit/accounts-receivable';
import { IconSettingsOutlined } from '@repo/monochrome-icons';
import styles from './header.strict-module.css';

interface HeaderProps extends FlowHeaderProps {
  onInvoiceSettingsPress: () => void;
}

interface InvoiceSettingsButtonProps {
  onPress: () => void;
}

function InvoiceSettingsButton({ onPress }: InvoiceSettingsButtonProps): React.ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={styles['settings-button']} data-test-flow-header-invoicing-settings-button="">
      <Tooltip
        buttonProps={{
          'aria-label': formatMessage({
            id: 'receivable-invoices.invoice-settings.cta.a11y.aria-label',
          }),
          iconOnly: true,
          onPress,
          variant: 'tertiary',
          size: 'large',
        }}
        label={formatMessage({ id: 'receivable-invoices.invoice-settings.cta.tooltip' })}
        needsButton
      >
        <IconSettingsOutlined />
      </Tooltip>
    </div>
  );
}

export function Header({ onInvoiceSettingsPress, ...rest }: HeaderProps): React.ReactNode {
  return (
    <FlowHeader
      actions={<InvoiceSettingsButton onPress={onInvoiceSettingsPress} />}
      className={styles.header}
      {...rest}
    />
  );
}
