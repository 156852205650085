import { BadgeStatus, Button, Disclaimer } from '@repo/design-system-kit';
import { useState, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './submit-banner.strict-module.css';

interface SubmitBannerProps {
  updateProcess: {
    legallyRequired: boolean;
    needsReview: boolean;
  };
  submitTask: {
    perform: () => Promise<void>;
  };
  disabled?: boolean;
  showMissingInfoDisclaimer?: boolean;
}

interface Texts {
  title: string;
  body: string;
  button: string;
  badge?: string;
}

export function SubmitBanner({
  updateProcess,
  submitTask,
  disabled,
  showMissingInfoDisclaimer,
  ...props
}: SubmitBannerProps): ReactNode {
  const { formatMessage } = useIntl();
  const [isRunning, setIsRunning] = useState(false);

  const handleSubmit = async (): Promise<void> => {
    setIsRunning(true);
    try {
      await submitTask.perform();
    } finally {
      setIsRunning(false);
    }
  };

  const { legallyRequired, needsReview } = updateProcess;

  const getTexts = (): Texts => {
    if (legallyRequired) {
      return {
        title: formatMessage({ id: 'organizations.profile.company-profile.banner-confirm.title' }),
        body: formatMessage({
          id: 'organizations.profile.company-profile.banner-confirm.subtitle',
        }),
        button: formatMessage({
          id: 'organizations.profile.company-profile.banner-confirm.button.confirm',
        }),
      };
    }

    if (needsReview) {
      return {
        title: formatMessage({ id: 'organizations.profile.company-profile.banner-review.title' }),
        body: formatMessage({ id: 'organizations.profile.company-profile.banner-review.subtitle' }),
        button: formatMessage({
          id: 'organizations.profile.company-profile.banner-review.button.submit',
        }),
        badge: formatMessage({ id: 'organizations.profile.company-profile.banner-review.tag' }),
      };
    }

    return {
      title: formatMessage({ id: 'organizations.profile.company-profile.banner-changes.title' }),
      body: formatMessage({ id: 'organizations.profile.company-profile.banner-changes.subtitle' }),
      button: formatMessage({
        id: 'organizations.profile.company-profile.banner-changes.button.confirm',
      }),
    };
  };

  const texts = getTexts();

  const getDisclaimerText = (): string => {
    if (legallyRequired) {
      return formatMessage({
        id: 'organizations.profile.company-profile.banner-confirm.error-message',
      });
    }
    return formatMessage({
      id: 'organizations.profile.company-profile.banner-review.error-message',
    });
  };

  const disclaimerText = getDisclaimerText();

  const getBannerIllustrationSrc = (): string => {
    if (legallyRequired) {
      return '/illustrations/settings/updates-required.svg';
    }
    if (needsReview) {
      return '/illustrations/settings/needs-review.svg';
    }
    return '/illustrations/settings/confirmed.svg';
  };

  const bannerIllustrationSrc = getBannerIllustrationSrc();

  return (
    <div className={styles['submit-banner']} {...props}>
      <div className={styles.banner}>
        <div className={styles.content}>
          <div className={styles['title-row']}>
            <span className={styles.title} data-test-title>
              {texts.title}
            </span>

            {texts.badge ? (
              <BadgeStatus
                className={styles.badge}
                data-test-badge=""
                level="in-progress"
                text={texts.badge}
              />
            ) : null}
          </div>

          <div className={styles.text} data-test-text>
            {texts.body}
          </div>

          {showMissingInfoDisclaimer ? (
            <Disclaimer.Inline
              className={styles['missing-info-disclaimer']}
              data-test-disclaimer=""
              level="error"
            >
              <span>{disclaimerText}</span>
            </Disclaimer.Inline>
          ) : null}

          <Button
            className={styles.button}
            data-test-submit-button
            isDisabled={disabled}
            isLoading={isRunning}
            onPress={() => void handleSubmit()}
            variant="primary"
          >
            {texts.button}
          </Button>
        </div>

        <div className={styles.illustration}>
          <StaticThemedAsset
            alt=""
            assetPath={bannerIllustrationSrc}
            className={styles.image}
            data-test-image
          />
        </div>
      </div>
    </div>
  );
}
