import { type ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import { InvoicePreview } from 'qonto/react/components/match-invoice/invoice-preview';

export function InvoiceDetails({
  invoice,
}: {
  invoice: SupplierInvoiceModel | undefined;
}): ReactNode {
  const intl = useIntl();
  const { featureBooleanApCreditNotes } = useFlags();

  const isCreditNoteAndFFEnabled = useMemo(
    (): boolean => Boolean(invoice?.isCreditNote && featureBooleanApCreditNotes),
    [invoice?.isCreditNote, featureBooleanApCreditNotes]
  );

  const titleText = useMemo(
    () =>
      isCreditNoteAndFFEnabled
        ? intl.formatMessage({
            id: 'supplier-invoices.match-transaction-modal.credit-note-details',
          })
        : intl.formatMessage({
            id: 'supplier-invoices.match-transaction-modal.invoice-details.title',
          }),
    [isCreditNoteAndFFEnabled, intl]
  );

  const supplierName = useMemo(
    (): string => (invoice?.supplierSnapshot?.name ?? invoice?.fileName ?? '') as string,
    [invoice?.supplierSnapshot?.name, invoice?.fileName]
  );

  return (
    <>
      <h3 className="caption mb-8" data-test-invoice-details-title="">
        {titleText}
      </h3>
      {invoice ? (
        <InvoicePreview
          amount={invoice.totalAmount.value}
          currency={invoice.totalAmount.currency}
          data-test-supplier-invoice-preview
          date={invoice.dueDate}
          isCreditNote={isCreditNoteAndFFEnabled}
          name={supplierName}
          nameLabel={intl.formatMessage({
            id: 'supplier-invoices.supplier-creation.supplier-name.label',
          })}
          showSignus
        />
      ) : null}
    </>
  );
}
