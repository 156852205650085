import type { ReactElement } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import styles from './styles.strict-module.css';

interface Client {
  kind: 'freelancer' | 'company';
  firstName?: string;
  lastName?: string;
  name?: string;
  billingAddress: {
    streetAddress: string;
    city: string;
    zipCode: string;
    countryCode: string;
  };
  email?: string | null;
}

interface CardProps {
  client: Client;
}

export function Card({ client }: CardProps): ReactElement {
  const { formatDisplayName } = useIntl();

  const clientCountryName = formatDisplayName(client.billingAddress.countryCode, {
    type: 'region',
  });

  const displayName =
    client.kind === 'company' ? client.name : `${client.firstName} ${client.lastName}`;

  return (
    <div className={styles.card} data-test-clients-sidebar-card>
      <h2
        className={cx('title-3 mb-8', styles.name, styles.overflow)}
        data-test-clients-sidebar-card-name
      >
        {displayName}
      </h2>

      <p
        className={cx('body-2', styles.street, styles.overflow)}
        data-test-clients-sidebar-card-street
      >
        {client.billingAddress.streetAddress}
      </p>

      <p
        className={cx('body-2', styles['zipcode-city-country'], styles.overflow)}
        data-test-clients-sidebar-card-zipcode-city-country
      >
        {client.billingAddress.zipCode} {client.billingAddress.city}, {clientCountryName}
      </p>

      {client.email ? (
        <p
          className={cx('body-2', styles.email, styles.overflow)}
          data-test-clients-sidebar-card-email
        >
          {client.email}
        </p>
      ) : null}
    </div>
  );
}
