import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Button, TextField } from '@repo/design-system-kit';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import type Organization from 'qonto/models/organization';
// @ts-expect-error The file needs to be migrated to TypeScript
import { ErrorInfo } from 'qonto/utils/error-info';

interface PromoCodeFormProps {
  organization: Organization; // Using any since we don't have the full Ember model type
  onPromoCodeSave: () => void;
}

interface ErrorInfoType {
  shouldSendToSentry: boolean;
  for: (error: Error) => ErrorInfoType;
}

export function PromoCodeForm({
  organization,
  onPromoCodeSave,
}: PromoCodeFormProps): React.ReactNode {
  const [promoCode, setPromoCode] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formatMessage } = useIntl();
  const store = useEmberService('store');
  const toastFlashMessages = useEmberService('toastFlashMessages');
  const segment = useEmberService('segment');
  const sentry = useEmberService('sentry');

  // @ts-expect-error The return type is intentionally void instead of Promise<void>
  const handleSubmit = async (e: React.FormEvent): void => {
    e.preventDefault();

    if (!promoCode?.trim()) {
      setErrorMessage(formatMessage({ id: 'subscription.promo-code.form.empty-error' }));
      return;
    }

    setIsSubmitting(true);
    let outcome;

    try {
      const discount = store.createRecord('discount', {
        organization,
        voucherCode: promoCode,
      });

      await discount.save();

      toastFlashMessages.toastSuccess(
        formatMessage({ id: 'subscription.promo-code.form.success-toast' })
      );

      setPromoCode(null);
      setErrorMessage(undefined);
      outcome = 'success';
    } catch (error: unknown) {
      const backendErrors = error as {
        errors?:
          | { detail: string }[]
          | {
              discounts?: string;
            };
      };

      const errorInfo = (ErrorInfo as ErrorInfoType).for(error as Error);
      let shouldSendToSentry = false;

      if (Array.isArray(backendErrors.errors)) {
        if (backendErrors.errors.some(({ detail }) => detail === 'invalid')) {
          setErrorMessage(formatMessage({ id: 'subscription.promo-code.form.invalid-error' }));
          outcome = 'invalid';
        } else if (backendErrors.errors.some(({ detail }) => detail === 'already_used')) {
          setErrorMessage(formatMessage({ id: 'subscription.promo-code.form.used-error' }));
          outcome = 'already_used';
        } else if (backendErrors.errors.some(({ detail }) => detail === 'reached_limit')) {
          setErrorMessage(
            formatMessage({ id: 'subscription.promo-code.form.reached-limit-error' })
          );
          outcome = 'reached_limit';
        } else {
          shouldSendToSentry = true;
        }
      } else if (backendErrors.errors?.discounts === 'rate_limit_reached') {
        setErrorMessage(
          formatMessage({ id: 'subscription.promo-code.form.rate-limit-reached-error' })
        );
        outcome = 'rate_limit_reached';
      } else {
        shouldSendToSentry = true;
      }

      if (shouldSendToSentry) {
        outcome = 'backend_error';
        if (errorInfo.shouldSendToSentry) {
          sentry.captureException(error);
        }
        toastFlashMessages.toastError(formatMessage({ id: 'toasts.errors.server_error' }));
      }
    } finally {
      setIsSubmitting(false);
      onPromoCodeSave();
      if (outcome) {
        segment.track('subscription_promo_clicked', { outcome });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        autoComplete="off"
        className="mb-16"
        data-test-promo-code-form-input
        errorMessage={errorMessage}
        isInvalid={Boolean(errorMessage)}
        label={formatMessage({ id: 'subscription.promo-code.form.label' })}
        onChange={(value: string) => {
          setPromoCode(value);
          setErrorMessage(undefined);
        }}
        placeholder={formatMessage({ id: 'subscription.promo-code.form.placeholder' })}
        value={promoCode || ''}
      />
      <Button
        data-test-promo-code-form-cta
        isLoading={isSubmitting}
        type="submit"
        variant="primary"
      >
        {formatMessage({ id: 'subscription.promo-code.form.cta' })}
      </Button>
    </form>
  );
}
