/* eslint-disable @typescript-eslint/no-misused-promises -- we dont need to await the async callbacks */
import { useIntl } from 'react-intl';
import { Button } from '@repo/design-system-kit';
import { SuggestedTransactionCard } from '@repo/domain-kit/accounts-payable';
import type { ReactElement } from 'react';
import { useState } from 'react';
import { safeLocalStorage } from '@repo/shared-config/app/helper/safe-local-storage';
import { useSegment } from '@repo/poly-hooks/segment';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { importAsset } from 'qonto/helpers/import-asset';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';
import {
  INVOICE_STATUSES,
  SUGGESTED_TRANSACTIONS_POPUP_DISMISSED_STORAGE_KEY,
} from 'qonto/constants/supplier-invoice';
import { withSuggestedTransaction } from '../suggested-transactions-popover/with-suggested-transaction';
import type { ComponentWithSuggestedTransactionProps } from '../suggested-transactions-popover/types';
import styles from './suggested-transaction-section.strict-module.css';

interface SuggestedTransactionSectionWrapperProps {
  invoice: SupplierInvoiceModel;
}

export function SuggestedTransactionSectionWrapper({
  invoice,
}: SuggestedTransactionSectionWrapperProps): ReactElement | null {
  const [isDismissed, setIsDismissed] = useState(false);
  const segment = useSegment();
  const { formatMessage } = useIntl();
  const toastFlashMessages = useEmberService('toast-flash-messages');

  const dismissedSuggestions = JSON.parse(
    safeLocalStorage.getItem(SUGGESTED_TRANSACTIONS_POPUP_DISMISSED_STORAGE_KEY) || '[]'
  ) as string[];

  const handleMatchTransactionSuccess = (): void => {
    segment.track('supplier-invoices_partial_match-button_match_clicked', {
      origin: 'invoice-details',
    });
    toastFlashMessages.toastSuccess(
      formatMessage({
        id: 'supplier-invoices.success-toast.mark-as-paid',
      })
    );
  };

  const transactionId = invoice.suggestedTransactions[0]?.id;
  const supplierInvoiceId = invoice.id;
  const attachmentId = invoice.attachmentId;
  const hasRequiredData = Boolean(invoice.supplierName && invoice.totalAmount);

  const shouldShowSuggestedTransaction =
    invoice.status === INVOICE_STATUSES.toReview &&
    transactionId &&
    !dismissedSuggestions.includes(supplierInvoiceId) &&
    !isDismissed &&
    hasRequiredData;

  return shouldShowSuggestedTransaction ? (
    <SuggestedTransactionSectionWithSuggestedTransaction
      attachmentId={attachmentId}
      onMatchSuccess={handleMatchTransactionSuccess}
      origin="invoice-details"
      setIsDismissed={setIsDismissed}
      supplierInvoiceId={supplierInvoiceId}
      transactionId={transactionId}
    />
  ) : null;
}

export function SuggestedTransactionSection({
  transaction,
  isLoading,
  handleDismissSuggestion,
  handleMatchTransaction,
  handleViewAllTransactions,
}: ComponentWithSuggestedTransactionProps): ReactElement {
  const { formatMessage } = useIntl();
  return (
    <section className={styles.section}>
      <header className={styles.header}>
        <img
          alt=""
          className={styles.icon}
          src={importAsset(['/icon/suggested_transactions.svg'])}
        />
        <h3 className={styles.title} data-testid="suggested-transactions-header">
          {formatMessage({ id: 'supplier-invoices.suggested-transaction.title' })}
        </h3>
        <p className={styles.subtitle} data-testid="suggested-transactions-subtitle">
          {formatMessage({ id: 'supplier-invoices.suggested-transaction.subtitle' })}
        </p>
      </header>
      <article className={styles.content} data-testid="suggested-transaction-card">
        <SuggestedTransactionCard
          handleDismissSuggestion={handleDismissSuggestion}
          handleMatchTransaction={handleMatchTransaction}
          isLoading={isLoading}
          transaction={transaction}
        />
      </article>
      <Button onPress={handleViewAllTransactions} variant="tertiary">
        {formatMessage({
          id: 'supplier-invoices.suggested-transaction.search-all-transactions.cta',
        })}
      </Button>
    </section>
  );
}

export const SuggestedTransactionSectionWithSuggestedTransaction = withSuggestedTransaction(
  SuggestedTransactionSection
);
