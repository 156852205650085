import { type ReactNode } from 'react';
import { Avatar } from '@repo/design-system-kit';
import type { Transaction, Card, Transfer, Income, EnrichmentData } from 'qonto/react/graphql';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { Side, SubjectType } from 'qonto/react/graphql';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import styles from './styles.strict-module.css';

const qontoLogo = 'https://qonto.com/customized-qonto-brand-logos/small.png';

type Subject = Card | Income | Transfer;

function isCardSubject(
  subject: Subject | undefined,
  subjectType: SubjectType | null | undefined
): subject is Card {
  return subjectType === SubjectType.Card;
}

function isIncomeSubject(
  subject: Subject | undefined,
  subjectType: SubjectType | null | undefined
): subject is Income {
  return subjectType === SubjectType.Income;
}

function isTransferSubject(
  subject: Subject | undefined,
  subjectType: SubjectType | null | undefined
): subject is Transfer {
  return subjectType === SubjectType.Transfer;
}

function getTransactionSubject({
  card,
  income,
  transfer,
  subjectType,
}: Transaction): Subject | undefined | null {
  switch (subjectType) {
    case SubjectType.Card:
      return card;
    case SubjectType.Income:
      return income;
    case SubjectType.Transfer:
      return transfer;
    default:
      return undefined;
  }
}

export function SourceCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { bankAccount, rawCounterpartyName, subjectType, side, enrichmentData } = transaction;
  const isBankAccount = side === Side.Debit;
  const subject = getTransactionSubject(transaction);

  let name;
  let subTitle;

  if (isBankAccount) {
    // Handle edge case where the current user may not have read access to the bank account
    if (bankAccount) {
      name = bankAccount.name;
      subTitle = displayIban(bankAccount.iban);
    }
  } else if (subject && isIncomeSubject(subject, subjectType)) {
    // TODO: Using subject.emitterName is temp fix as Source column is hidden \
    // Consult with Product on what to show when we unhide this column
    name = subject.emitterName;
    subTitle = displayIban(subject.iban);
  } else if (subject && isCardSubject(subject, subjectType)) {
    name = rawCounterpartyName ?? '';
  }

  return (
    <BaseCell>
      {name ? (
        <DataWithIconCell
          icon={getCellIcon(name, isBankAccount, enrichmentData)}
          subtitle={subTitle}
          title={name}
        />
      ) : (
        <span className={styles['not-available']}>Not available</span>
      )}
    </BaseCell>
  );
}

export function TargetCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { bankAccount, rawCounterpartyName, subjectType, side, enrichmentData } = transaction;

  const isBankAccount = side === Side.Credit;
  const subject = getTransactionSubject(transaction);

  let name;
  let subTitle;

  if (isBankAccount) {
    // Handle edge case where the current user may not have read access to the bank account
    if (bankAccount) {
      name = bankAccount.name;
      subTitle = displayIban(bankAccount.iban);
    }
  } else if (subject && isTransferSubject(subject, subjectType)) {
    name = subject.beneficiary?.name ?? '';
    subTitle = displayIban(subject.beneficiary?.iban ?? '');
  } else if (subject && isCardSubject(subject, subjectType)) {
    name = rawCounterpartyName;
  }

  return (
    <BaseCell>
      {name ? (
        <DataWithIconCell
          icon={getCellIcon(name, isBankAccount, enrichmentData)}
          subtitle={subTitle}
          title={name}
        />
      ) : (
        <span className={styles['not-available']}>Not available</span>
      )}
    </BaseCell>
  );
}

function getCellIcon(
  name: string,
  isBankAccount: boolean,
  enrichmentData: EnrichmentData
): ReactNode {
  const bankAccountIcon = <Avatar alt={name} data-testid="avatar" size="24" src={qontoLogo} />;
  const enrichedAvatar = enrichmentData.logo?.small;

  const counterPartyIcon = enrichedAvatar ? (
    <Avatar alt={name} data-testid="avatar" size="24" src={enrichedAvatar} />
  ) : (
    <Avatar alt={name} data-testid="avatar" name={name.split(' ')[0]} size="24" />
  );

  return isBankAccount ? bankAccountIcon : counterPartyIcon;
}

function displayIban(iban: string): string {
  return `⋅⋅ ${iban.slice(-14).toUpperCase()}`;
}
