import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'clsx';
import { Checkbox } from '@repo/design-system-kit';
import { SortIcon } from 'qonto/react/components/paginated-table/header/sort/sort';
import styles from './styles.strict-module.css';

type SortField = 'last_changed_on' | 'creditor_name' | 'execution_date' | 'amount';

type SortOrder = 'asc' | 'desc';

type SortFull = `${SortField}:${SortOrder}`;

interface HeaderProps {
  sort?: SortFull;
  onSortChange: (sort: SortFull) => void;
  areAllSelected: boolean;
  onSelectAll: () => void;
}

export function Header({
  sort,
  onSortChange,
  areAllSelected,
  onSelectAll,
}: HeaderProps): ReactNode {
  const sortField = sort?.split(':')[0] as SortField;
  const sortOrder = sort?.split(':')[1] === 'asc';

  const handleSortBy = (field: SortField): void => {
    const isAscending = sortField === field && sortOrder;
    onSortChange(`${field}:${isAscending ? 'desc' : 'asc'}`);
  };

  return (
    <tr>
      <th className={cx(styles['header-cell'], styles['checkbox-cell'])} scope="col">
        <Checkbox
          data-test-table-header-checkbox
          isSelected={areAllSelected}
          onChange={onSelectAll}
        />
      </th>
      <th
        className={cx(
          styles['header-cell'],
          styles['creditor-cell'],
          sortField === 'creditor_name' && styles.active
        )}
        data-test-table-header-creditor
        onClick={() => {
          handleSortBy('creditor_name');
        }}
        scope="col"
      >
        <button className={styles['header-content']} type="button">
          <FormattedMessage id="riba.table.heading.creditor" />
          <SortIcon isActive={sortField === 'creditor_name'} isAscending={sortOrder} />
        </button>
      </th>
      <th
        className={cx(styles['header-cell'], sortField === 'execution_date' && styles.active)}
        data-test-table-header-due-date
        onClick={() => {
          handleSortBy('execution_date');
        }}
        scope="col"
      >
        <button className={styles['header-content']} type="button">
          <FormattedMessage id="riba.table.heading.due-date" />
          <SortIcon isActive={sortField === 'execution_date'} isAscending={sortOrder} />
        </button>
      </th>
      <th
        className={cx(
          styles['header-cell'],
          styles['amount-cell'],
          sortField === 'amount' && styles.active
        )}
        data-test-table-header-amount
        onClick={() => {
          handleSortBy('amount');
        }}
        scope="col"
      >
        <button className={styles['header-content']} type="button">
          <FormattedMessage id="riba.table.heading.amount" />
          <SortIcon isActive={sortField === 'amount'} isAscending={sortOrder} />
        </button>
      </th>
      <th className={styles['header-cell']} data-test-table-header-actions scope="col" />
    </tr>
  );
}
