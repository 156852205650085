import type { ReactElement } from 'react';
import cx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';
import { LottiePlayer } from '@repo/design-system-kit';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

const BACKGROUND_COLORS = ['purple', 'mint', 'mustard', 'peach'];
const backgroundColor =
  BACKGROUND_COLORS[Math.floor(Math.random() * BACKGROUND_COLORS.length)] || 'mint';

interface SigninSidepanelProps {
  showNrcPromotionalContent: boolean;
}

function PromotionalSidepanel(): ReactElement {
  const { formatMessage } = useIntl();
  return (
    <div className={cx(styles.illustration, styles.mint)}>
      <div className={styles.mainImageContainer}>
        <img
          alt=""
          className={styles.mainImage}
          data-test-signin-nrc-payments-main-image
          src="/illustrations/signin/nrc-payments.svg"
        />
        <p className="body-1 mb-8" data-test-nrc-payments-promotion-title>
          <FormattedMessage id="aeat.login-screen.caption" />
        </p>
        <p
          className="title-2 mb-4 ml-8 mr-8"
          dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'aeat.login-screen.body' }) }}
          data-test-nrc-payments-promotion-description
        />
      </div>
    </div>
  );
}

export function SigninSidepanel({ showNrcPromotionalContent }: SigninSidepanelProps): ReactElement {
  const lottiePath = useThemedAssetPath('/lotties/account-aggregation-login.json');

  if (showNrcPromotionalContent) {
    return <PromotionalSidepanel />;
  }

  return (
    <div className={cx(styles.illustration, styles[backgroundColor])}>
      <div className={styles.mainImageContainer}>
        <LottiePlayer autoplay data-test-sidepanel-illustration loop path={lottiePath} />
        <p className="body-1 mb-8" data-test-sidepanel-subtitle>
          <FormattedMessage id="sign_in.sidepanel.subtitle" />
        </p>
        <p className="title-2 mb-4 ml-8 mr-8" data-test-sidepanel-title>
          <FormattedMessage id="sign_in.sidepanel.title" />
        </p>
      </div>
    </div>
  );
}
