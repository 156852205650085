import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { FullscreenBase } from '@repo/design-system-kit';
import { useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { Modal, ModalOverlay } from 'react-aria-components';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import ENV from 'qonto/config/environment';
import { useFetchSupplierInvoice } from 'qonto/react/hooks/use-fetch-supplier-invoice';
import { InvoiceDetails } from './invoice-details';
import styles from './match-transaction-modal.strict-module.css';

const isTest = ENV.environment === 'test';

const MODAL_ANIMATION_MS = isTest ? 0 : 250;

interface RouteQueryParams {
  // this query param always exists because we check it in the route
  invoice_id: string;
}

export function MatchTransactionModal(): ReactNode {
  const router = useRouter();
  const intl = useIntl();
  const { organization } = useOrganizationManager();
  const [isOpen, setIsOpen] = useState(true);

  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;
  const closeModal = useCallback((): void => {
    setIsOpen(false);

    setTimeout(
      () => void router.push(`/organizations/${organization.slug}/supplier-invoices/list`),
      MODAL_ANIMATION_MS
    );
  }, [organization.slug, router]);

  const query = router.query as RouteQueryParams;
  const { data: invoice } = useFetchSupplierInvoice(query.invoice_id);

  return (
    <ModalOverlay
      UNSTABLE_portalContainer={TEST_PORTAL_CONTAINER}
      className={styles.overlay}
      isOpen={isOpen}
    >
      <Modal
        className={styles.modal}
        isKeyboardDismissDisabled
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      >
        <FullscreenBase
          onClose={() => {
            closeModal();
          }}
        >
          <section className={styles.main}>
            <h1
              className={cx('title-1', 'mb-32', styles.title)}
              data-testid="match-transaction-title"
            >
              {intl.formatMessage({ id: 'supplier-invoices.match-transaction-modal.title' })}
            </h1>
            <InvoiceDetails invoice={invoice} />
          </section>
        </FullscreenBase>
      </Modal>
    </ModalOverlay>
  );
}
