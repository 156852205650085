import { useEffect, useCallback, useState, type ReactNode } from 'react';
import {
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnPinningState,
  type RowSelectionState,
} from '@tanstack/react-table';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import window from 'ember-window-mock';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import cx from 'clsx';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { bulkSelectionManager } from 'qonto/react/contexts/bulk-selection-context';
import { CellProvider } from 'qonto/react/contexts/cell-context';
import type { Transaction } from 'qonto/react/graphql';
import { useTrackRender } from 'qonto/react/hooks/use-track-render';
import { SORTABLE_COLUMNS } from 'qonto/react/constants';
import { DEFAULT_COLUMN_WIDTH, type DisplayColumn } from 'qonto/constants/table-view';
import { useResizeObserver } from 'qonto/react/hooks/ui';
import { HeaderCell } from './components/header-cell';
import styles from './styles.strict-module.css';
import { BodyCell } from './components/body-cell';

interface TableProps<TData, TValue> {
  data: TData[];
  columns: ColumnDef<TData, TValue>[];
  updateColumn: (column: Partial<DisplayColumn>) => void;
  defaultColumnOrder: string[];
  handleSortBy?: (sortBy: string | undefined) => void;
  isScrolled?: boolean;
  sortBy?: string;
  containerWidth: number;
}

type TablesPreferences = {
  columnOrder?: string[];
  columnSizing?: Record<string, number>;
} | null;

export function DataTable<TData extends Transaction, TValue>({
  data,
  columns,
  updateColumn,
  handleSortBy,
  isScrolled,
  sortBy,
  defaultColumnOrder,
  containerWidth,
}: TableProps<TData, TValue>): ReactNode {
  useTrackRender({ eventName: 'transactions-modular-table-loaded' });
  /*feature--boolean-modular-tables-hide-show-columns*/
  const { featureBooleanModularTablesHideShowColumns } = useFlags();
  const isHideShowColumnsFFEnabled = Boolean(featureBooleanModularTablesHideShowColumns);
  const tablesPreferences = JSON.parse(
    window.localStorage.getItem('tablesPreferences') ?? '{}'
  ) as TablesPreferences;
  const rightAlignedHeaders = ['amount', 'settledBalance'];
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: ['bulk-select', 'transaction'],
  });
  const [columnOrder, setColumnOrder] = useState<string[]>(() =>
    isHideShowColumnsFFEnabled
      ? defaultColumnOrder
      : (tablesPreferences?.columnOrder ?? defaultColumnOrder)
  );
  const [totalColumnWidth, setTotalColumnWidth] = useState(0);

  // handles selection at the table level
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  // handles selection at the context level
  const { setSelection, selectedItemIds, shouldResetSelection, resetSelection } =
    bulkSelectionManager.useBulkSelection();

  const shouldShowFillerColumn = isHideShowColumnsFFEnabled && totalColumnWidth < containerWidth;

  const tableColumns = shouldShowFillerColumn
    ? [
        ...columns,
        {
          id: 'filler',
          accessorKey: 'filler',
          header: '',
          size: containerWidth - totalColumnWidth,
          enableSorting: false,
        },
      ]
    : columns;

  useEffect(() => {
    setRowSelection(prev => {
      const initialRowSelection = selectedItemIds.reduce<RowSelectionState>((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
      return { ...prev, ...initialRowSelection };
    });
  }, [selectedItemIds]);

  const table = useReactTable({
    data,
    columns: tableColumns,
    state: { columnPinning, columnOrder, rowSelection },
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    getRowId: row => row.id,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onColumnPinningChange: setColumnPinning,
    onColumnOrderChange: setColumnOrder,
  });

  const computeTotalColumnWidth = useCallback((): number => {
    return columns.reduce((acc, column) => acc + (column.size ?? DEFAULT_COLUMN_WIDTH), 0);
  }, [columns]);

  const handleColumnWidthChange = useCallback(
    (columnId: string, width: number): void => {
      if (!isHideShowColumnsFFEnabled) {
        return;
      }

      updateColumn({ id: columnId, width });
      setTotalColumnWidth(computeTotalColumnWidth());
    },
    [computeTotalColumnWidth, isHideShowColumnsFFEnabled, updateColumn]
  );

  useResizeObserver(table.getState(), handleColumnWidthChange);

  useEffect(() => {
    if (!isHideShowColumnsFFEnabled) {
      return;
    }

    setTotalColumnWidth(computeTotalColumnWidth());
  }, [computeTotalColumnWidth, isHideShowColumnsFFEnabled]);

  useEffect(() => {
    setColumnOrder(defaultColumnOrder);
  }, [defaultColumnOrder]);

  useEffect(() => {
    setSelection(Object.keys(rowSelection));
  }, [rowSelection, setSelection]);

  useEffect(() => {
    if (shouldResetSelection) {
      table.resetRowSelection();
      resetSelection();
    }
  }, [shouldResetSelection, table, resetSelection]);

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = columnOrder.indexOf(active.id as string);
      const newIndex = columnOrder.indexOf(over.id as string);

      if (isHideShowColumnsFFEnabled) {
        updateColumn({
          id: active.id as string,
          position: newIndex,
        });
      }

      const newColumnOrder = arrayMove(columnOrder, oldIndex, newIndex);
      setColumnOrder(newColumnOrder);
      const newPref = { ...tablesPreferences, columnOrder: newColumnOrder };

      if (!isHideShowColumnsFFEnabled) {
        window.localStorage.setItem('tablesPreferences', JSON.stringify(newPref));
      }
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const resizedColumn = table.getState().columnSizingInfo.isResizingColumn;

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <table className={styles['data-table']}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                {headerGroup.headers.map((header, index) => (
                  <HeaderCell
                    className={cx(index === 0 && isScrolled && styles['table-cell-scrolled'])}
                    handleSortBy={handleSortBy}
                    header={header}
                    isHideShowColumnsFFEnabled={isHideShowColumnsFFEnabled}
                    key={header.id}
                    resizedColumn={resizedColumn}
                    sortBy={sortBy}
                    updateColumn={updateColumn}
                    {...(rightAlignedHeaders.includes(header.id) && { align: 'right' })}
                    {...(SORTABLE_COLUMNS.includes(header.id) && { isSortable: true })}
                  />
                ))}
              </SortableContext>
            </tr>
          ))}
        </thead>
        {table.getRowModel().rows.length ? (
          <tbody>
            {table.getRowModel().rows.map(row => (
              <CellProvider key={row.id} transaction={row.original}>
                <tr
                  className={styles['table-row']}
                  data-test-transaction-row={row.original.id}
                  data-testid="transaction-row"
                >
                  {row.getVisibleCells().map((cell, index) => (
                    <SortableContext
                      items={columnOrder}
                      key={cell.id}
                      strategy={horizontalListSortingStrategy}
                    >
                      <BodyCell
                        cell={cell}
                        cellIndex={index}
                        className={cx(
                          styles['table-cell'],
                          styles['table-cell-hovered'],
                          index === 0 && isScrolled && styles['table-cell-scrolled']
                        )}
                        key={cell.id}
                      />
                    </SortableContext>
                  ))}
                </tr>
              </CellProvider>
            ))}
          </tbody>
        ) : null}
      </table>
    </DndContext>
  );
}
