import { type ComponentPropsWithRef, type ReactNode } from 'react';
import cx from 'clsx';
import { DialogTrigger, Button } from 'react-aria-components';
import { ActionSlot } from '../../action-slot';
import dataTableStyles from '../../data-table/styles.strict-module.css';
import styles from './styles.strict-module.css';

interface BaseCellProps extends ComponentPropsWithRef<'div'> {
  align?: 'left' | 'right';
  actionSlot?: ReactNode;
  popoverSlot?: ReactNode;
  onOpenChange?: (isOpen: boolean) => void;
  'data-testid'?: string;
  className?: string;
  actionSlotAlignment?: 'left' | 'right';
  showActionSlotOnRowHover?: boolean;
}

export function BaseCell({
  children,
  actionSlot,
  popoverSlot,
  align = 'left',
  actionSlotAlignment = 'left',
  onOpenChange,
  className,
  showActionSlotOnRowHover = false,
  ...props
}: BaseCellProps): ReactNode {
  const hasPopoverSlot = Boolean(popoverSlot);
  const hasActionSlot = Boolean(actionSlot);

  const popoverTrigger = (
    <Button className={styles['cell-popover-trigger']} data-testid="popover-trigger">
      {children}
      {popoverSlot}
    </Button>
  );

  const cellContent = (
    <div {...props} className={cx(styles.cell, styles[`align-${align}`], className)}>
      {hasPopoverSlot ? popoverTrigger : children}
      {hasActionSlot ? (
        <ActionSlot
          className={cx(
            styles['cell-actions'],
            styles[`action-align-${actionSlotAlignment}`],
            showActionSlotOnRowHover
              ? dataTableStyles['cell-actions-display']
              : styles['cell-actions-display']
          )}
        >
          {actionSlot}
        </ActionSlot>
      ) : null}
    </div>
  );

  if (hasPopoverSlot) {
    return <DialogTrigger onOpenChange={onOpenChange}>{cellContent}</DialogTrigger>;
  }

  return cellContent;
}
