import { useRef, useState, type ReactNode } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks/use-ember-service';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';
import { parseFormattedAmount, getAmountProperties } from 'qonto/utils/amount';
import type { Amount } from 'qonto/react/models/amount';
import type { CategoriesTableRow } from 'qonto/react/components/cash-flow/models/categories-table-display.ts';
import type { LabelTableInterval } from 'qonto/react/components/cash-flow/models/labels-cashflow-display.ts';
import { useUpdateCashFlowForecast } from 'qonto/react/hooks/use-update-forecast-entries';
import type { ForecastEntry } from 'qonto/react/models/cash-flow-forecast-entry';
import { type CashFlowSidePanelPayload } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import styles from './styles.strict-module.css';

const SENSITIVE_KEYS = ['Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];

interface BalanceCellProps {
  amount: Amount | undefined;
  forecastAmount?: Amount | undefined;
  interval: LabelTableInterval | undefined;
  categories: CategoriesTableRow[]; // a balance can be related to multiple categories (type, parent, subcategory)
  onForecastEntryUpdate?: () => void;
  onNavigateDown?: (enterEditMode: boolean) => void;
  onNavigateLeft?: (enterEditMode: boolean) => void;
  onNavigateRight?: (enterEditMode: boolean) => void;
  onNavigateUp?: (enterEditMode: boolean) => void;
  onViewTransactions?: (payload: CashFlowSidePanelPayload) => void;
  isSelectedCell?: boolean;
  isForecastEditingEnabled?: boolean;
  isFlowSelected?: boolean;
}

export function BalanceCell({
  amount,
  interval,
  categories,
  onForecastEntryUpdate,
  onNavigateDown,
  onNavigateLeft,
  onNavigateRight,
  onNavigateUp,
  onViewTransactions,
  forecastAmount,
  isForecastEditingEnabled = false,
  isFlowSelected = false,
  isSelectedCell = false,
}: BalanceCellProps): ReactNode {
  const queryClient = useQueryClient();
  const { locale, formatMessage, formatNumber } = useIntl();

  const segment = useEmberService('segment');

  const amountValue = Number(amount?.value ?? 0);
  const amountCurrency = amount?.currency ?? 'EUR';

  const forecastCurrency = forecastAmount?.currency ?? 'EUR';

  const forecastValue = Math.abs(Number(forecastAmount?.value ?? 0));

  const currentDate = dayjs().utc();
  const intervalDate = dayjs(interval?.start).utc();

  const isFutureMonth = intervalDate.isAfter(currentDate, 'month');
  const isCurrentMonth = intervalDate.isSame(currentDate, 'month');

  const forecastAmountString = formatNumber(forecastValue, {
    style: 'currency',
    currency: forecastCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(forecastAmountString);
  const [isFlashing, setIsFlashing] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const cellRef = useRef<HTMLButtonElement | null>(null);

  const toastFlashMessages = useEmberService('toast-flash-messages');

  const { mutate: mutateCashFlowForecastAndNavigateDown } = useUpdateCashFlowForecast({
    onSuccess: async () => {
      onNavigateDown?.(true);
      setIsFlashing(false); // Reset first to remove the class
      void document.body.offsetHeight; // Trigger a reflow
      setIsFlashing(true); // Apply the class again
      setTimeout(() => {
        setIsFlashing(false); // Remove the class after the animation
      }, 1000); // Match animation duration
      await queryClient.invalidateQueries({ queryKey: ['cashflow-timeseries'] });
      onForecastEntryUpdate?.();
    },
    onError: () => {
      setEditValue(forecastAmountString);
      toastFlashMessages.toastError(formatMessage({ id: 'cash-flow.edit-forecast.error.toast' }));
    },
  });

  const { mutate: mutateCashFlowForecast } = useUpdateCashFlowForecast({
    onSuccess: async () => {
      setIsFlashing(false); // Reset first to remove the class
      void document.body.offsetHeight; // Trigger a reflow
      setIsFlashing(true); // Apply the class again
      setTimeout(() => {
        setIsFlashing(false); // Remove the class after the animation
      }, 1000); // Match animation duration
      await queryClient.invalidateQueries({ queryKey: ['cashflow-timeseries'] });
      onForecastEntryUpdate?.();
    },
    onError: () => {
      setEditValue(forecastAmountString);
      toastFlashMessages.toastError(formatMessage({ id: 'cash-flow.edit-forecast.error.toast' }));
    },
  });

  if (!amount || isNaN(amountValue)) {
    return <span data-testid="balance-cell-empty">-</span>;
  }

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (!SENSITIVE_KEYS.includes(e.key)) return;
    e.preventDefault();

    switch (e.key) {
      case 'ArrowUp':
        onNavigateUp?.(false);
        break;
      case 'ArrowDown':
        onNavigateDown?.(false);
        break;
      case 'ArrowLeft':
        onNavigateLeft?.(false);
        break;
      case 'ArrowRight':
        onNavigateRight?.(false);
        break;
      case 'Enter':
        handleClick();
        break;
    }
  };

  const handleClick = (): void => {
    if (!categories.length) return;
    if (isFutureMonth) {
      handleStartEditMode();
      return;
    }

    onViewTransactions?.({
      selectedCategories: categories,
      selectedInterval: interval,
      isFlowSelected,
    });
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEditValue(e.target.value);
  };

  const handleStartEditMode = (): void => {
    if (!isForecastEditingEnabled) return;
    setIsEditing(true);
    segment.track('cash-flow_forecast_input');
    requestAnimationFrame(() => {
      const input = inputRef.current;
      if (!input) return;

      const value = input.value;
      const regex = /(?<number>\d[\d.,]*)\s?(?=\D|$)/;

      const match = regex.exec(value);

      input.focus();

      if (match?.groups?.number) {
        const start = value.indexOf(match.groups.number); // Find start index manually
        const end = start + match.groups.number.trim().length; // Calculate end index
        input.setSelectionRange(start, end);
      }
    });
  };

  const updateForecastEntry = (navigateDown: boolean): void => {
    setIsEditing(false);

    if (!interval || !forecastCurrency || !categories[0]?.id) return;
    segment.track('cash-flow_forecast_input-confirmed');
    const date = new Date(interval.start);
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    // @ts-expect-error -- TODO: remove this once we have a proper currency type
    const properties = getAmountProperties(forecastCurrency, locale);
    const formattedAmount = parseFormattedAmount(editValue.replace(/[^\d,.]/g, ''), properties);
    const editValueNumeric = isNaN(formattedAmount) ? 0 : formattedAmount;

    const editedForecastAmountStringWithCurrency = formatNumber(editValueNumeric, {
      style: 'currency',
      currency: forecastCurrency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    setEditValue(editedForecastAmountStringWithCurrency);

    const forecastEntries: ForecastEntry[] = [
      {
        amount: {
          valid: true,
          value: {
            currency: forecastCurrency,
            value: `${editValueNumeric}`,
          },
        },
        category_id: categories[0].id,
        month,
        year,
      },
    ];

    if (navigateDown) {
      mutateCashFlowForecastAndNavigateDown({
        forecast_entries: forecastEntries,
      });
    } else {
      mutateCashFlowForecast({ forecast_entries: forecastEntries });
    }
  };

  const handleEditKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    switch (e.key) {
      case 'Enter': {
        updateForecastEntry(true);
        break;
      }
      case 'Escape':
        setIsEditing(false);
        setEditValue(forecastAmountString);
        cellRef.current?.focus();
        break;
    }

    if (
      !/[0-9\s,.]/.test(e.key) &&
      !['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const renderForecastContainer = (): JSX.Element | null => {
    if (!(isFutureMonth || isCurrentMonth) || isNaN(forecastValue)) return null;

    return (
      <div className={`${styles.forecastContainer}`} data-test-forecast-container>
        {isEditing ? (
          <input
            className={styles.balanceInput}
            data-test-forecast-amount-edit-mode
            inputMode="decimal"
            onBlur={() => {
              updateForecastEntry(false);
            }}
            onChange={handleEditChange}
            onKeyDown={handleEditKeyDown}
            pattern="[0-9.,]*"
            ref={inputRef}
            value={editValue}
          />
        ) : (
          <span
            className={`${styles.forecastAmount} ${
              !isForecastEditingEnabled && styles.forecastAmountDisabled
            } ${styles.forecastAmountEditable}
            ${isFlashing ? styles.forecastAmountFlash : ''}`}
            data-edit-mode-button
            data-test-forecast-amount
            onClick={handleStartEditMode}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleClick();
              }
            }}
            role="button"
            tabIndex={-1}
          >
            {editValue}
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      <button
        className={`${styles['balance-button']} ${isEditing ? styles.isEditing : ''} ${
          isSelectedCell ? styles['balance-button-selected'] : ''
        }`}
        data-test-balance-cell-button
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        ref={cellRef}
        type="button"
      />
      <div
        className={`${styles.balanceButtonContent} ${isCurrentMonth && !isEditing ? styles.dualContent : ''}`}
        data-testid="balance-cell"
      >
        {!isFutureMonth && (
          <button
            className={`${styles.amountContainer} ${isEditing ? styles.fadeOut : ''}`}
            onClick={handleClick}
            tabIndex={-1}
            type="button"
          >
            <FormattedNumber
              currency={amountCurrency}
              maximumFractionDigits={0}
              minimumFractionDigits={0}
              style="currency"
              value={amountValue}
            />
          </button>
        )}
        {!isEditing && isCurrentMonth ? <span className={styles.forecastSeparator}>/</span> : null}
        {renderForecastContainer()}
      </div>
    </>
  );
}
