import { type ReactNode, useMemo } from 'react';
import { cashFlowSidePanelManager } from 'qonto/react/contexts/cash-flow-sidepanel-context';
import { SidePanel } from 'qonto/react/shared/components/side-panel';
import { CashflowPeriodRate } from 'qonto/react/models/cash-flow-period';
import { SidepanelHeader } from 'qonto/react/components/cash-flow/components/sidepanel/header';
import styles from './cash-flow-sidepanel.strict-module.css';
import { TransactionsTableWrapper } from './transactions-table/transactions-table-wrapper';

export function CashFlowSidePanel(): ReactNode {
  const {
    isVisible,
    selectedCategories,
    isFlowSelected,
    selectedInterval,
    selectedFrequency,
    bankAccounts,
    periodNavigation,
    closeSidepanel,
    refreshChart,
  } = cashFlowSidePanelManager.useCashFlowSidePanel();

  const frequencyFormat = useMemo(() => {
    if (selectedFrequency === CashflowPeriodRate.Yearly) {
      return 'YYYY';
    } else if (selectedFrequency === CashflowPeriodRate.Quarterly) {
      return `[Q]Q YYYY`;
    }
    return 'MMMM YYYY';
  }, [selectedFrequency]);

  return (
    <SidePanel
      data-testid="cash-flow-sidepanel"
      id="cash-flow-sidepanel"
      isVisible={isVisible}
      onClickOutside={closeSidepanel}
      options={{
        ignore: ['#cash-flow-category-popover', '.flash-message'],
      }}
      width={445}
    >
      <div className={styles['cash-flow-sidepanel-wrapper']}>
        <SidepanelHeader
          frequencyFormat={frequencyFormat}
          isFlowSelected={isFlowSelected}
          onCloseSidepanel={closeSidepanel}
          periodNavigation={periodNavigation}
          selectedCategories={selectedCategories}
          selectedInterval={selectedInterval}
        />
        <TransactionsTableWrapper
          bankAccounts={bankAccounts}
          isFlowSelected={isFlowSelected}
          refreshChart={refreshChart}
          selectedCategories={selectedCategories}
          selectedInterval={selectedInterval}
        />
      </div>
    </SidePanel>
  );
}
