import type { ReactNode } from 'react';
import cx from 'clsx';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import type BankAccount from 'qonto/models/bank-account';
import type { BankAccountLogo } from 'qonto/models/bank-account';
import styles from './bank-account-avatar.strict-module.css';

const QONTO_DEFAULT_LOGO = '/illustrations/app/qonto-logo.svg';

export function BankAccountAvatar({
  bankAccount,
  className,
  size = 'small',
}: {
  bankAccount: BankAccount;
  className?: string;
  size?: 'small' | 'medium';
}): ReactNode {
  const logo = bankAccount.logo as BankAccountLogo | undefined;
  const bankName = bankAccount.name as string;
  const selectedLogo = logo?.[size];
  const bankLogoSrc = useThemedAssetPath(selectedLogo || QONTO_DEFAULT_LOGO);

  if (!bankLogoSrc) return null;

  return (
    <div className={cx(className, styles.container)}>
      <img
        alt={bankName}
        className={styles.avatar}
        data-test-account-avatar={bankName}
        loading="lazy"
        src={bankLogoSrc}
      />
    </div>
  );
}
