import type { ReactNode } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import { Timeline as DSTimeline, type TimelineItemProps } from '@repo/design-system-kit';
import type RibaPaymentModel from 'qonto/models/riba-payment';
import { ArrowBottomRoundedFilled } from 'qonto/react/assets/icons/arrow-bottom-rounded-filled';
import { ClockOutlined } from 'qonto/react/assets/icons/clock-outlined';
import { EuroOutlined } from 'qonto/react/assets/icons/euro-outlined';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { DetailsList } from 'qonto/react/components/sidebar/details-list';
import styles from './styles.strict-module.css';

interface TimelineProps {
  payment: RibaPaymentModel;
}

function isDue(date: Date | undefined): boolean {
  if (!date) {
    return false;
  }

  return date.getTime() < Date.now();
}

function getTimelineItems(payment: RibaPaymentModel, intl: IntlShape): TimelineItemProps[] {
  const items: TimelineItemProps[] = [
    {
      id: '1',
      lineVariant: 'dashed' as const,
      slots: {
        media: <ArrowBottomRoundedFilled />,
        title: intl.formatMessage({ id: 'riba.sidebar.timeline.received.title' }),
        content: dateToken({
          date: payment.receivedAt.toISOString(),
          locale: intl.locale,
          token: DATE_FORMAT_TOKENS.DATE_TIME_S,
        }),
      },
    },
    {
      id: '2',
      lineVariant: 'dashed' as const,
      slots: {
        media: <ClockOutlined />,
        title: intl.formatMessage({ id: 'riba.sidebar.timeline.approved.title' }),
        content: intl.formatMessage(
          {
            id: 'riba.sidebar.timeline.approved.due-date',
          },
          {
            dueDate: dateToken({
              date: payment.executionDate.toISOString(),
              locale: intl.locale,
              token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
            }),
          }
        ),
      },
    },
    {
      id: '3',
      lineVariant: 'dashed' as const,
      slots: {
        media: <EuroOutlined />,
        title: intl.formatMessage({ id: 'riba.sidebar.timeline.debited.title' }),
        content: isDue(payment.executionDate)
          ? intl.formatMessage(
              { id: 'riba.sidebar.timeline.debited.due-date' },
              {
                dueDate: dateToken({
                  date: payment.executionDate.toISOString(),
                  locale: intl.locale,
                  token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
                }),
              }
            )
          : undefined,
      },
    },
  ];

  return items;
}

export function Timeline({ payment }: TimelineProps): ReactNode {
  const intl = useIntl();
  return (
    <div className={styles.timeline}>
      <DetailsList
        items={[
          {
            label: intl.formatMessage({ id: 'riba.sidebar.timeline.title' }),
            text: '',
          },
        ]}
      />
      <DSTimeline list={getTimelineItems(payment, intl)} />
    </div>
  );
}
