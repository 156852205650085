import { Timeline } from '@repo/design-system-kit';
import type { ReactNode } from 'react';
import {
  IconCheckmarkRoundedFilled,
  IconClockOutlined,
} from '@repo/design-system-kit/assets/icons';
import { useIntl } from 'react-intl';
import { IconCheckmarkRoundedOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import {
  ProcessingEventName,
  ProcessingEventStatus,
  type ProcessingEvent,
} from 'qonto/services/international-out/types';
import styles from './styles.strict-module.css';

interface ProcessingEventsTimelineProps {
  events?: ProcessingEvent[];
  isLoading?: boolean;
}

export function ProcessingEventsTimeline({
  events = [],
  isLoading = false,
}: ProcessingEventsTimelineProps): ReactNode {
  const intl = useIntl();

  const getIcon = ({ order, status }: ProcessingEvent): ReactNode => {
    if (status === ProcessingEventStatus.InProgress) {
      return (
        <IconCheckmarkRoundedFilled
          className={cx(styles.icon, styles.progress)}
          data-testid="progress-icon"
        />
      );
    }

    if (status === ProcessingEventStatus.Completed) {
      if (order !== events.length - 1) {
        return (
          <IconCheckmarkRoundedOutlined
            className={cx(styles.icon, styles.completed)}
            data-testid="completed-icon"
          />
        );
      }

      return (
        <IconCheckmarkRoundedFilled
          className={cx(styles.icon, styles.paid)}
          data-testid="paid-icon"
        />
      );
    }

    return (
      <IconClockOutlined className={cx(styles.icon, styles.awaiting)} data-testid="awaiting-icon" />
    );
  };

  return (
    <Timeline
      isLoading={isLoading}
      list={events.map(event => ({
        id: String(event.name),
        slots: {
          media: getIcon(event),
          title: (
            <span data-testid="name">
              {intl.formatMessage({
                id: `international-out.timeline.step.${event.name.toLowerCase()}`,
              })}
            </span>
          ),
          ...(event.date && {
            content: (
              <span data-testid="date">
                {dateToken({
                  date: event.date,
                  locale: intl.locale,
                  token: 'date-time-s',
                })}
              </span>
            ),
          }),
        },
        lineVariant:
          event.status === ProcessingEventStatus.Completed
            ? ('solid' as const)
            : ('dashed' as const),
      }))}
      loadingOptions={{ itemsCount: Object.values(ProcessingEventName).length }}
    />
  );
}
