import cx from 'clsx';
import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import type { FinancingModel } from 'qonto/react/financing/api/models/financing';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import { IconStatusCancelled } from 'qonto/react/assets/icons/status';
import { formatMoney } from 'qonto/react/shared/utils/format-money';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import styles from './styles.strict-module.css';

interface RowProps {
  financing: FinancingModel;
}

export function Row({ financing }: RowProps): ReactNode {
  const intl = useIntl();

  return (
    <>
      <td aria-hidden="true" className={styles.empty} />

      <td className={cx('body-1', styles.cell, styles.supplier)}>
        <div className={styles.supplierContent}>
          <div className={styles.supplierAvatar}>
            <StatusAvatar
              aria-hidden="true"
              data-test-enriched-logo
              hasBorder={financing.avatarInfo.hasBorder}
              icon={
                financing.isDeclined ? (
                  <IconStatusCancelled data-test-status-avatar-icon={financing.status} />
                ) : undefined
              }
              iconSize="16"
              isDisabled={financing.isDeclined}
              isLazyLoaded
              src={financing.avatarInfo.smallLogo}
            />
          </div>
          <div className={styles.overflowHidden}>
            <p className={styles.ellipsis} data-test-beneficiary-name>
              {financing.beneficiaryName}
            </p>
            {financing.isDeclined ? (
              <p className={styles.financingStatus} data-test-financing-status>
                {intl.formatMessage({
                  id: 'financing.pay-later.cockpit.table.transaction.status.declined',
                })}
              </p>
            ) : (
              <p className={styles.installments} data-test-installments>
                {intl.formatMessage(
                  {
                    id: 'financing.pay-later.cockpit.table.row.supplier.installments',
                  },
                  {
                    count: financing.paidInstallments,
                    current: financing.paidInstallments,
                    total: financing.totalInstallments,
                  }
                )}
              </p>
            )}
          </div>
        </div>
      </td>

      <td
        className={cx(
          'body-2',
          styles.cell,
          styles.completedOn,
          financing.isDeclined && styles.declined
        )}
        data-test-completed-on
      >
        {financing.isDeclined
          ? '-'
          : financing.completedAt &&
            dateToken({
              locale: intl.locale,
              date: financing.completedAt,
              token: 'date-year-s',
            })}
      </td>

      <td
        className={cx(styles.cell, styles.financedAmount, financing.isDeclined && styles.declined)}
      >
        <p className={styles.amount} data-test-financed-amount>
          {financing.isDeclined
            ? '-'
            : formatMoney(Number(financing.financedAmount.value), {
                locale: intl.locale,
                currency: financing.financedAmount.currency,
              })}
        </p>
      </td>

      <td
        className={cx(styles.cell, styles.totalRepayment, financing.isDeclined && styles.declined)}
        data-test-total-repayment
      >
        {formatMoney(Number(financing.totalAmount.value), {
          locale: intl.locale,
          currency: financing.totalAmount.currency,
        })}
      </td>

      <td aria-hidden="true" className={styles.empty} />
    </>
  );
}
