import { createContext, type ReactNode, useCallback, useContext, useMemo, useReducer } from 'react';
import dayjs, { type QUnitType } from 'dayjs';
import type { CategoriesTableRow } from '../components/cash-flow/models/categories-table-display';
import type { LabelTableInterval } from '../components/cash-flow/models/labels-cashflow-display';
import { CashflowPeriodRate } from '../models/cash-flow-period';

export interface CashFlowSidePanelPayload {
  selectedCategories: CategoriesTableRow[];
  selectedInterval: LabelTableInterval | undefined;
  isFlowSelected: boolean;
}

interface ContextState extends CashFlowSidePanelPayload {
  isVisible: boolean;
  selectedFrequency?: CashflowPeriodRate;
  bankAccounts: string[];
}

interface ContextActions {
  openSidepanelWith: (payload: CashFlowSidePanelPayload) => void;
  closeSidepanel: () => void;
  refreshChart: () => void;
  periodNavigation: {
    onNextMonth?: () => void;
    isLastPeriod?: boolean;
    onPreviousMonth?: () => void;
    isFirstPeriod?: boolean;
  };
}

export type CashFlowSidePanelContextType = ContextState & ContextActions;

const initialState: ContextState = {
  isVisible: false,
  selectedCategories: [],
  selectedInterval: undefined,
  selectedFrequency: CashflowPeriodRate.Monthly,
  bankAccounts: [],
  isFlowSelected: false,
};

const CashFlowSidePanelContext = createContext<CashFlowSidePanelContextType | undefined>(undefined);

type Action =
  | {
      type: 'OPEN_SIDEPANEL';
      payload: CashFlowSidePanelPayload;
    }
  | { type: 'CLOSE_SIDEPANEL' }
  | { type: 'UPDATE_INTERVAL'; payload: { interval: LabelTableInterval | undefined } };

function reducer(state: ContextState, action: Action): ContextState {
  switch (action.type) {
    case 'OPEN_SIDEPANEL':
      return {
        isVisible: true,
        selectedCategories: action.payload.selectedCategories,
        selectedInterval: action.payload.selectedInterval,
        isFlowSelected: action.payload.isFlowSelected,
        bankAccounts: state.bankAccounts,
      };
    case 'CLOSE_SIDEPANEL':
      return initialState;
    case 'UPDATE_INTERVAL':
      return {
        ...state,
        selectedInterval: action.payload.interval,
      };
    default:
      return state;
  }
}

interface CashFlowSidePanelProviderProps {
  children: ReactNode;
  selectedFrequency?: CashflowPeriodRate;
  bankAccounts: string | undefined;
  onSelectInterval?: (interval: LabelTableInterval | undefined) => void;
  onRefreshChart?: () => void;
  periodNavigation: {
    onNextMonth?: () => void;
    isLastPeriod?: boolean;
    onPreviousMonth?: () => void;
    isFirstPeriod?: boolean;
  };
}

export function CashFlowSidePanelProvider({
  children,
  onSelectInterval,
  onRefreshChart,
  selectedFrequency,
  bankAccounts,
  periodNavigation,
}: CashFlowSidePanelProviderProps): ReactNode {
  const [state, dispatch] = useReducer(reducer, initialState);

  const bankAccountsCollection = useMemo(() => {
    return bankAccounts ? bankAccounts.split(',') : [];
  }, [bankAccounts]);

  const openSidepanelWith = useCallback(
    ({ selectedCategories, selectedInterval, isFlowSelected }: CashFlowSidePanelPayload) => {
      dispatch({
        type: 'OPEN_SIDEPANEL',
        payload: { selectedCategories, selectedInterval, isFlowSelected },
      });
      onSelectInterval?.(selectedInterval);
    },
    [onSelectInterval]
  );

  const wrappedPeriodNavigation = useMemo(() => {
    const setSelectedInterval = ({ isPreviousPeriod = true }): void => {
      let frequency: QUnitType;
      if (CashflowPeriodRate.Quarterly === selectedFrequency) {
        frequency = 'quarter';
      } else {
        frequency = selectedFrequency === CashflowPeriodRate.Yearly ? 'year' : 'month';
      }

      let interval;

      if (isPreviousPeriod) {
        interval = {
          start: dayjs(state.selectedInterval?.start).subtract(1, frequency).valueOf(),
          end: dayjs(state.selectedInterval?.end).subtract(1, frequency).valueOf(),
        };
      } else {
        interval = {
          start: dayjs(state.selectedInterval?.start).add(1, frequency).valueOf(),
          end: dayjs(state.selectedInterval?.end).add(1, frequency).valueOf(),
        };
      }

      dispatch({ type: 'UPDATE_INTERVAL', payload: { interval } });
    };
    return {
      onNextMonth: () => {
        setSelectedInterval({ isPreviousPeriod: false });
        if (periodNavigation.onNextMonth && !periodNavigation.isLastPeriod) {
          periodNavigation.onNextMonth();
        }
      },
      onPreviousMonth: () => {
        setSelectedInterval({ isPreviousPeriod: true });
        if (periodNavigation.onPreviousMonth && !periodNavigation.isFirstPeriod) {
          periodNavigation.onPreviousMonth();
        }
      },
      isLastPeriod: periodNavigation.isLastPeriod,
      isFirstPeriod: periodNavigation.isFirstPeriod,
    };
  }, [
    periodNavigation,
    selectedFrequency,
    state.selectedInterval?.end,
    state.selectedInterval?.start,
  ]);

  const closeSidepanel = useCallback(() => {
    dispatch({ type: 'CLOSE_SIDEPANEL' });
  }, []);

  const refreshChart = useCallback(() => {
    onRefreshChart?.();
  }, [onRefreshChart]);

  return (
    <CashFlowSidePanelContext.Provider
      value={{
        ...state,
        openSidepanelWith,
        closeSidepanel,
        refreshChart,
        selectedFrequency,
        bankAccounts: bankAccountsCollection,
        periodNavigation: wrappedPeriodNavigation,
      }}
    >
      {children}
    </CashFlowSidePanelContext.Provider>
  );
}

export function useCashFlowSidePanel(): CashFlowSidePanelContextType {
  const context = useContext(CashFlowSidePanelContext);

  if (!context) {
    throw new Error('useCashFlowSidePanel must be used within a CashFlowSidePanelProvider');
  }

  return context;
}

export const cashFlowSidePanelManager = {
  useCashFlowSidePanel,
};
