import { FormattedMessage } from 'react-intl';
import { type ReactElement } from 'react';
import { Button } from '@repo/design-system-kit';
import { useSegment } from '@repo/poly-hooks/segment';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

export interface FailureProps {
  backToStep: (stepId: string) => void;
  goToCockpit: () => void;
}

export function Failure({ backToStep, goToCockpit }: FailureProps): ReactElement {
  const segment = useSegment();

  const handlePrimaryCta = (): void => {
    segment.track('pay-later_try-again_clicked', { origin: 'yousign_failure' });
    backToStep('value-proposition');
  };

  const handleSecondaryCta = (): void => {
    segment.track('pay-later-cockpit_displayed', { origin: 'failure' });
    goToCockpit();
  };

  return (
    <div className={styles.failure}>
      <StaticThemedAsset
        assetPath={useThemedAssetPath('/illustrations/financing/pay-later/failure.svg')}
        className={styles['failure-illustration']}
        data-test-failure-illustration
      />
      <h1 className="title-2 mb-16 align-center" data-test-failure-title>
        <FormattedMessage id="financing.pay-later.application-flow.failure.title" />
      </h1>
      <p className="body-1 align-center mb-32" data-test-failure-description>
        <FormattedMessage id="financing.pay-later.application-flow.failure.description" />
      </p>
      <Button className="mb-16" data-test-failure-primary-cta onPress={handlePrimaryCta}>
        <FormattedMessage id="financing.pay-later.application-flow.failure.primary-cta" />
      </Button>
      <Button data-test-failure-secondary-cta onPress={handleSecondaryCta} variant="tertiary">
        <FormattedMessage id="financing.pay-later.application-flow.failure.secondary-cta" />
      </Button>
    </div>
  );
}
