import { AmountField, Label } from '@repo/design-system-kit';
import { CollapseOptions } from '@repo/domain-kit/shared';
import type { ChangeEvent, HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import styles from './styles.strict-module.css';

interface VatRateOption {
  value: number | null;
  clearable?: boolean;
  label: string;
}

interface VatRateProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  vatRate?: string;
  selectedVatOption?: VatRateOption;
  vatRateOnInput?: (value: string) => void;
  vatRateOnChange: (value: number) => void;
  updateVatOption: (option?: VatRateOption) => void;
  message?: string | null;
  isDisabled?: boolean;
  className?: string;
}

export function VatRate({
  label,
  vatRate,
  isDisabled,
  vatRateOnChange,
  selectedVatOption,
  updateVatOption,
  vatRateOnInput,
  message,
  ...props
}: VatRateProps): JSX.Element {
  const { formatMessage, formatNumber } = useIntl();
  const { organization } = useOrganizationManager();

  const vatRateOptions: VatRateOption[] = organization.vatRatesCategories.map(
    (value: number | null) => {
      let optionLabel = '';
      let clearable = false;
      if (value === null) {
        optionLabel = formatMessage({ id: 'transactions.sidebar.vat.accounting.none' });
      } else if (value > 0) {
        optionLabel = formatNumber(value / 100, { style: 'percent' });
      } else if (value === 0) {
        optionLabel = formatMessage({ id: 'transactions.sidebar.vat.accounting.no-rate' });
      } else {
        optionLabel = formatMessage({ id: 'transactions.sidebar.vat.accounting.other' });
        clearable = true;
      }

      return {
        clearable,
        label: optionLabel,
        value,
      };
    }
  );

  function handleInput(e: ChangeEvent<HTMLInputElement>): void {
    vatRateOnInput?.(e.currentTarget.value);
  }

  return (
    <div {...props}>
      <div className="mb-4">
        <Label data-test-label>{label}</Label>
      </div>
      <CollapseOptions
        collapsedContent={
          <AmountField
            amount={vatRate}
            currency="%"
            data-test-percentage-field=""
            decimals={1}
            isDisabled={isDisabled}
            label=""
            minValue={0}
            onChange={vatRateOnChange}
            onInput={handleInput}
          />
        }
        data-test-collapse-group=""
        disabled={isDisabled}
        onUpdate={updateVatOption}
        options={vatRateOptions}
        selectedOption={selectedVatOption}
      >
        {option => <div data-test-vat-rate-option="">{option.label}</div>}
      </CollapseOptions>
      {message ? (
        <div className={styles.error}>
          <span
            className="caption form-elements-message form-elements-message--error"
            data-test-form-elements-message=""
            data-test-has-message="Select a VAT rate."
            data-test-level="error"
          >
            {message}
          </span>
        </div>
      ) : null}
    </div>
  );
}
