import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { DetailsList } from 'qonto/react/components/sidebar/details-list';
import type RibaPaymentModel from 'qonto/models/riba-payment';

interface DetailsProps {
  payment: RibaPaymentModel;
}

export function Details({ payment }: DetailsProps): ReactNode {
  const intl = useIntl();

  const items = [
    {
      label: intl.formatMessage({ id: 'riba.sidebar.labels.reference' }),
      text: payment.reference,
    },
    {
      label: intl.formatMessage({ id: 'riba.sidebar.labels.riba-number' }),
      text: payment.ribaNumber,
    },
    {
      label: intl.formatMessage({ id: 'labels.account_from' }),
      text: payment.bankAccountId,
    },
  ];

  return <DetailsList items={items} />;
}
